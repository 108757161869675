import { TStateMap, TStateParams, TStateStyleProperties } from 'theme/theme.types';

const STATE_MAP: TStateMap = {
  onTap: {
    backgroundColor: 'buttonGhostPrimary',
    borderRadius: 12,
  },
  hover: {
    backgroundColor: 'backgroundTertiaryHover',
    borderRadius: 12,
  },
  default: {
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
};

export function getStateStyle({ pressed, hovered }: TStateParams): TStateStyleProperties {
  if (pressed) {
    return STATE_MAP.onTap;
  }
  if (hovered) {
    return STATE_MAP.hover;
  }
  return STATE_MAP.default;
}
