import React from 'react';
import { Controller, UseControllerReturn } from 'react-hook-form';
import { getOr } from 'lodash/fp';

import Box from 'components/core/Box';
import FormErrorLabel from 'components/v2/core/FormErrorLabel';
import SelectDate from 'components/v2/SelectDate';
import Card from 'components/v2/core/Card';

import { TI18nId } from 'types/common';

import { TFieldSelectDateProps } from './FieldSelectDate.types';

function FieldSelectDate({
  name,
  labelId,
  control,
  errors,
  marginBottom = 'm',
}: TFieldSelectDateProps) {
  const errorMessageId = getOr(undefined, name, errors)?.message;

  function handleRender({ field }: UseControllerReturn) {
    const { onChange, value } = field;
    return (
      <Card p="s">
        <SelectDate labelId={labelId} value={value} onApplySelectDate={onChange} />
      </Card>
    );
  }

  return (
    <Box marginBottom={marginBottom}>
      <Controller name={name} control={control} render={handleRender} />
      {errorMessageId && <FormErrorLabel textId={errorMessageId as TI18nId} />}
    </Box>
  );
}

export default FieldSelectDate;
