import React, { useImperativeHandle, useRef, useState } from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';

import PageModal from 'components/PageModal';
import { NavigationHeaderRemoveButton } from 'components/v2/Navigation';
import PopconfirmModal from 'modals/PopconfirmModal';
import { AnnotationQuestionIcon } from 'components/v2/Icons';

import { showErrorAlert, showSuccessAlert } from 'helpers/showAlertModal';
import { useModal } from 'components/core/Modal';
import CardSpendingLimitType, {
  getRequestKeyByCardSpendingLimitType,
} from 'enums/CardSpendingLimitType';
import { TCommonResponse, TI18nId } from 'types/common';
import API_ENDPOINT from 'constants/apiEndpoint';
import replaceUrlParams from 'helpers/replaceUrlParams';
import { TCardSpendingLimitsResponse } from 'types/card';
import { TPopconfirmModalRef } from 'modals/PopconfirmModal/PopconfirmModal.types';

import {
  TCardDetailSpendLimitModalProps,
  TCardSpendLimitFormData,
  TModalState,
} from './CardDetailSpendLimitModal.types';
import CardSpendLimitModalForm from './views/CardDetailSpendLimitModalForm';

function CardDetailSpendLimitModal({ modalRef }: TCardDetailSpendLimitModalProps) {
  const ref = useModal();
  const [state, setState] = useState<TModalState>();
  const [submitFormLoading, setSubmitFormLoading] = useState<boolean>(false);
  const { put: requestUpdateSpendLimits, response: updateSpendLimitsResponse } = useFetch<
    TCommonResponse<TCardSpendingLimitsResponse>
  >(
    replaceUrlParams(API_ENDPOINT.CARD_DETAIL_SPENDING_LIMITS, {
      cardId: state?.card.id,
    })
  );
  const popconfirmModalRef = useRef<TPopconfirmModalRef>(null);

  const cardSpendLimit = state?.cardSpendLimit;
  const hasLimit = cardSpendLimit?.periodic || !!cardSpendLimit?.singleTransaction?.amount;

  useImperativeHandle(modalRef, () => ({
    show: showModal,
  }));

  function showModal(state: TModalState) {
    ref.current?.show(true);
    setState(state);
  }

  function openRemoveCardSpendLimitModal() {
    popconfirmModalRef.current?.show({
      titleId: 'messages.cardSpendLimit.remove.title',
      descriptionId: 'messages.cardSpendLimit.remove.description',
      contentIcon: (
        <AnnotationQuestionIcon iconColor="statusWarningPrimary" width={64} height={64} />
      ),
      onConfirm: submitRemoveCardSpendLimit,
      confirmButtonProps: { id: 'button.confirm' },
    });
  }

  async function submitRemoveCardSpendLimit() {
    try {
      const data = await requestUpdateSpendLimits({});

      if (!updateSpendLimitsResponse.ok) {
        return showErrorAlert({
          titleId: (data.errorMessage ?? 'errors.unexpectedError') as TI18nId,
        });
      }
      await state?.onCardSpendLimitUpdate?.();
      ref.current?.show(false);
      return showSuccessAlert({
        titleId: 'messages.cardSpendLimit.success.title',
      });
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({
        titleId: 'errors.unexpectedError',
      });
    }
  }

  async function submitCardSpendLimitForm(values: TCardSpendLimitFormData) {
    try {
      setSubmitFormLoading(true);
      const hasSpendLimit =
        values.cardSpendingLimitType &&
        Number(values.spendLimitAmount) > 0 &&
        values.isSpendLimitActive;
      const hasSingleTransactionLimit =
        Number(values.singleTransactionAmount) > 0 && values.isSingleTransactionLimitActive;

      const data = await requestUpdateSpendLimits({
        ...(hasSpendLimit && {
          [getRequestKeyByCardSpendingLimitType(
            values.cardSpendingLimitType as CardSpendingLimitType
          )]: {
            amount: values.spendLimitAmount,
          },
        }),
        ...(hasSingleTransactionLimit && {
          [getRequestKeyByCardSpendingLimitType(CardSpendingLimitType.SINGLE_TRANSACTION)]: {
            amount: values.singleTransactionAmount,
          },
        }),
      });

      if (!updateSpendLimitsResponse.ok) {
        return showErrorAlert({
          title: data.errorMessage,
        });
      }
      await state?.onCardSpendLimitUpdate?.();
      ref.current?.show(false);
      return showSuccessAlert({
        titleId: 'messages.cardSpendLimit.success.title',
      });
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({
        titleId: 'errors.unexpectedError',
      });
    } finally {
      setSubmitFormLoading(false);
    }
  }

  return (
    <>
      <PageModal
        modalRef={ref}
        headerTitleId="placeholder.empty"
        isStaticSmallHeader
        sideLayoutVisible={false}
        {...(hasLimit && {
          rightButton: <NavigationHeaderRemoveButton onPress={openRemoveCardSpendLimitModal} />,
        })}
        shouldHideSideLayoutOnVisibilityChange={false}>
        {cardSpendLimit && (
          <CardSpendLimitModalForm
            card={state.card}
            onSubmit={submitCardSpendLimitForm}
            loading={submitFormLoading}
            defaultValues={{
              cardSpendingLimitType:
                cardSpendLimit?.periodic?.type ?? CardSpendingLimitType.MONTHLY,
              spendLimitAmount: cardSpendLimit?.periodic?.amount?.toString(),
              singleTransactionAmount: cardSpendLimit?.singleTransaction?.amount?.toString(),
              isSpendLimitActive: !!cardSpendLimit?.periodic?.amount,
              isSingleTransactionLimitActive: !!cardSpendLimit?.singleTransaction?.amount,
            }}
          />
        )}
      </PageModal>
      <PopconfirmModal ref={popconfirmModalRef} />
    </>
  );
}

export default CardDetailSpendLimitModal;
