import React, { memo } from 'react';

import Text from 'components/v2/core/Text';

import { TTextInputLabelProps } from './TextInputLabel.types';
import { InputLabel } from './TextInputLabel.styles';

function TextInputLabel({ htmlFor, textId, textColor, isPositionTop }: TTextInputLabelProps) {
  return (
    <InputLabel htmlFor={htmlFor} isPositionTop={isPositionTop}>
      <Text textId={textId} color={textColor} variant="textBodySmall" />
    </InputLabel>
  );
}

export default memo(TextInputLabel);
