import React, { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from 'components/core/Box';
import SideLayoutPage from 'components/SideLayoutPage';
import WamoCard from 'components/WamoCard';

import { TCommonResponse } from 'types/common';
import API_ENDPOINT from 'constants/apiEndpoint';
import replaceUrlParams from 'helpers/replaceUrlParams';
import { getCardHolderName } from 'helpers/getCardHolderTitle';
import { TCard, TCardSpendingLimitsResponse } from 'types/card';
import { isCardFrozenOrFreezing } from 'enums/CardStatus';
import { getWamoCardVariantByColorType } from 'enums/CardColorType';

import { TCardDetailProps } from './CardDetail.types';
import CardDetailShimmer from './views/CardDetailShimmer';
import CardDetailContent from './views/CardDetailContent';
import CardOrderStatuses from './views/CardOrderStatuses';
import CardActivating from './views/CardActivating';
import { getCardStatuses } from './CardDetail.helpers';

function CardDetail({ id }: TCardDetailProps) {
  const [loading, setLoading] = useState(true);
  const { data, get: requestCardDetail } = useFetch<TCommonResponse<TCard>>(
    replaceUrlParams(API_ENDPOINT.CARD_DETAIL, {
      cardId: id,
    })
  );
  const {
    data: cardSpendingLimitsData,
    get: retrieveCardDetailSpendingLimits,
    loading: retrieveCardDetailSpendingLimitsLoading,
  } = useFetch<TCommonResponse<TCardSpendingLimitsResponse>>(
    replaceUrlParams(API_ENDPOINT.CARD_DETAIL_SPENDING_LIMITS, {
      cardId: id,
    }),
    []
  );

  const location = useLocation();
  const navigate = useNavigate();

  const card = data?.result as TCard;
  const spendingLimits = cardSpendingLimitsData?.result;

  useEffect(() => {
    handleRequestCardDetail();
  }, [id]);

  async function handleRequestCardDetail() {
    setLoading(true);
    await requestCardDetail();
    setLoading(false);
  }

  const { isCardActivating, isPhysicalCardOrdered, canSeeCardDetail } = getCardStatuses(card);

  async function handleFetchCardDetail() {
    await requestCardDetail();
  }

  if (loading) {
    return <CardDetailShimmer />;
  }

  return (
    <SideLayoutPage headerId="placeholder.empty" onClose={() => navigate(location.pathname)}>
      <Box alignment="col.verticalCenter">
        <Box px="xl">
          <WamoCard
            cardType={card.type}
            nameOnCard={getCardHolderName(card.cardHolder)}
            truncatedPan={card.truncatedPan}
            isFrozen={isCardFrozenOrFreezing(card.status)}
            variant={getWamoCardVariantByColorType(card.color)}
            size="default"
            isCardTypeBadgeVisible
          />
        </Box>
        <Box>
          {isCardActivating && (
            <CardActivating type={card.type} onPolling={handleFetchCardDetail} />
          )}
          {isPhysicalCardOrdered && (
            <CardOrderStatuses
              createdAt={card.createdAt}
              cardId={card.id}
              refetchCardDetail={handleFetchCardDetail}
              shippingAddress={card.shippingAddress}
            />
          )}
          {canSeeCardDetail && (
            <CardDetailContent
              onRequestCardDetail={requestCardDetail}
              card={card}
              spendingLimits={spendingLimits}
              retrieveCardDetailSpendingLimitsLoading={retrieveCardDetailSpendingLimitsLoading}
              retrieveCardDetailSpendingLimits={retrieveCardDetailSpendingLimits}
            />
          )}
        </Box>
      </Box>
    </SideLayoutPage>
  );
}

export default CardDetail;
