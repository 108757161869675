import React, { useImperativeHandle, useRef } from 'react';

import PageModal from 'components/PageModal';
import Box from 'components/core/Box';
import Text from 'components/core/Text';
import ListCardButton from 'components/ListCardButton';
import Card from 'components/core/Card';
import { TrashIcon } from 'components/Icons';
import MemberPermission from 'components/MemberPermission';
import TeamMemberItem from 'components/TeamMemberItem';
import CardItem from 'components/v2/CardItem';
import { Speedometer01Icon } from 'components/v2/Icons';

import CardDetailSpendLimitModal from 'modals/CardDetailSpendLimitModal';

import { useModal } from 'components/core/Modal';
import { TI18nId } from 'types/common';
import MemberType from 'enums/MemberType';
import { TCardDetailSpendLimitModalRef } from 'modals/CardDetailSpendLimitModal/CardDetailSpendLimitModal.types';

import { TCardDetailSettingsModalProps } from './CardDetailSettingsModal.types';
import CardSecuritySection from './views/CardSecuritySection';

function CardDetailSettingsModal({
  modalRef,
  card,
  onShowCardPinClick,
  onCardTerminateClick,
  onCardUnblockPinClick,
  spendingLimits,
  retrieveCardDetailSpendingLimits,
}: TCardDetailSettingsModalProps) {
  const ref = useModal();
  const cardDetailSpendLimitModalRef = useRef<TCardDetailSpendLimitModalRef>(null);

  useImperativeHandle(modalRef, () => ({
    show: showModal,
  }));

  function showModal() {
    ref.current?.show(true);
  }

  return (
    <>
      <PageModal
        modalRef={ref}
        headerTitleId={card.label as TI18nId}
        sideLayoutVisible={false}
        shouldHideSideLayoutOnVisibilityChange={false}>
        <Box>
          <Card p="s" mb="l">
            <CardItem card={card} />
          </Card>
          <Text id="label.spendingLimit" variant="mediumTextBold" mb="s" />
          {card.spendingLimitAvailable && (
            <MemberPermission allowTo={[MemberType.DIRECTOR]}>
              <Card mb="l">
                <ListCardButton
                  titleId="label.cardSpendLimit"
                  descriptionId="label.seeSpendLimits"
                  titleProps={{ variant: 'mediumTextBold', mb: 'none' }}
                  leftComponent={
                    <Speedometer01Icon width={32} height={32} iconColor="textPrimary" />
                  }
                  onClick={() =>
                    cardDetailSpendLimitModalRef.current?.show({
                      card,
                      cardSpendLimit: spendingLimits,
                      onCardSpendLimitUpdate: retrieveCardDetailSpendingLimits,
                    })
                  }
                  mb="none"
                />
              </Card>
            </MemberPermission>
          )}
          <CardSecuritySection
            card={card}
            onClickShowPin={onShowCardPinClick}
            onClickUnblockPin={onCardUnblockPinClick}
          />
          <Text id="label.connectedMember" variant="mediumTextBold" mb="s" />
          <Card p="m" mb="l">
            <TeamMemberItem item={card.cardHolder} />
          </Card>
          <MemberPermission allowTo={[MemberType.DIRECTOR, MemberType.MANAGER]}>
            <Card>
              <ListCardButton
                titleId="screens.cardDetail.terminate.title"
                descriptionId="screens.cardDetail.terminate.description"
                titleProps={{ variant: 'mediumTextBold' }}
                leftComponent={<TrashIcon width={32} height={32} iconColor="error" />}
                onClick={onCardTerminateClick}
                mb="none"
              />
            </Card>
          </MemberPermission>
        </Box>
      </PageModal>
      <CardDetailSpendLimitModal modalRef={cardDetailSpendLimitModalRef} />
    </>
  );
}

export default CardDetailSettingsModal;
