import { TTagStateStyleMap } from './Tag.types';

export const TAG_BORDER_WIDTH = 2;
export const TAG_BORDER_RADIUS = 8;
export const TAG_MIN_HEIGHT = 40;
export const TAG_ICON_SIZE = 24;

export const TAG_STATE_STYLE_MAP: TTagStateStyleMap = {
  default: {
    background: 'statusNaturalGhost',
    border: 'statusNaturalPrimary',
    text: 'statusNaturalPrimary',
  },
  onTap: {
    background: 'statusNaturalGhostHover',
    border: 'statusNaturalPrimaryHover',
    text: 'statusNaturalPrimaryHover',
  },
  selected: {
    background: 'statusNaturalPrimary',
    border: 'statusNaturalGhostHover',
    text: 'statusNaturalGhost',
  },
};
