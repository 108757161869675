import { TTextLinkSizeMap, TTextLinkStatesStyleMap } from './TextLink.types';

export const TEXT_LINK_STATE_MAP: TTextLinkStatesStyleMap = {
  default: {
    text: 'statusNaturalPrimary',
  },
  disabled: {
    text: 'textPassive',
  },
  onTap: {
    text: 'statusNaturalPrimaryOnTap',
  },
};

export const TEXT_LINK_SIZE_MAP: TTextLinkSizeMap = {
  small: {
    minHeight: 16,
    iconSize: 16,
    textVariant: 'textBodySmallBold',
  },
  medium: {
    minHeight: 32,
    iconSize: 24,
    textVariant: 'textBodySubBold',
  },
  large: {
    minHeight: 48,
    iconSize: 24,
    textVariant: 'textBodyBold',
  },
};
