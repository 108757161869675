import { TI18nId } from 'types/common';

enum InvoiceCustomerType {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL',
}

const MAP: Record<
  InvoiceCustomerType,
  { labelId: TI18nId; nameInputLabelId: TI18nId; vatInputLabelId: TI18nId }
> = {
  [InvoiceCustomerType.COMPANY]: {
    labelId: 'label.business',
    nameInputLabelId: 'label.companyName',
    vatInputLabelId: 'label.vatNumber',
  },
  [InvoiceCustomerType.INDIVIDUAL]: {
    labelId: 'label.individual',
    nameInputLabelId: 'label.customerName',
    vatInputLabelId: 'label.vatNumberOptional',
  },
};

export function getInvoiceCustomerTypeOptions() {
  return [
    {
      labelId: getLabelIdByInvoiceCustomerType(InvoiceCustomerType.COMPANY),
      value: InvoiceCustomerType.COMPANY,
    },
    {
      labelId: getLabelIdByInvoiceCustomerType(InvoiceCustomerType.INDIVIDUAL),
      value: InvoiceCustomerType.INDIVIDUAL,
    },
  ];
}

export function getLabelIdByInvoiceCustomerType(type: InvoiceCustomerType) {
  return MAP[type]?.labelId;
}

export function getNameInputLabelIdByInvoiceCustomerType(type: InvoiceCustomerType) {
  return MAP[type]?.nameInputLabelId;
}

export function getVatInputLabelIdByInvoiceCustomerType(type: InvoiceCustomerType) {
  return MAP[type]?.vatInputLabelId;
}

export default InvoiceCustomerType;
