import { TCallingCodeStyleValues, TFieldCallingCodeStates } from './FieldPhoneNumber.types';

export function getCallingCodeStateStyle({
  hasError,
  pressed,
  hovered,
}: TFieldCallingCodeStates): TCallingCodeStyleValues {
  if (hasError) {
    return {
      backgroundColor: 'statusErrorGhost',
      borderColor: 'statusErrorPrimary',
    };
  }
  if (hovered) {
    return {
      backgroundColor: 'backgroundSecondaryHover',
      borderColor: 'transparent',
    };
  }
  if (pressed) {
    return {
      backgroundColor: 'backgroundTertiary',
      borderColor: 'borderOutlineActive',
    };
  }
  return {
    backgroundColor: 'backgroundSecondary',
    borderColor: 'transparent',
  };
}
