import { object } from 'yup';

import {
  REQUIRED_VALIDATION,
  EMAIL_VALIDATION,
  PHONE_NUMBER_V2_VALIDATION,
  NEXT_INVOICE_NUMBER_VALIDATION,
} from 'constants/validation';

export const FORM_VALIDATION_SCHEMA = object().shape({
  invoiceNumberPrefix: REQUIRED_VALIDATION,
  nextInvoiceNumber: NEXT_INVOICE_NUMBER_VALIDATION,
  contactPhoneNumber: PHONE_NUMBER_V2_VALIDATION,
  contactEmail: EMAIL_VALIDATION,
});
