import { object, string } from 'yup';

import {
  REQUIRED_VALIDATION,
  COUNTRY_VALIDATION,
  POST_CODE_VALIDATION,
  NOT_REQUIRED_VALIDATION,
  INVOICE_TOWN_OR_CITY_VALIDATION,
  INVOICE_ADDRESS_LINE_VALIDATION,
  EMAIL_VALIDATION,
} from 'constants/validation';
import InvoiceCustomerType from 'enums/InvoiceCustomerType';

export const CUSTOMER_INFORMATION_FORM_VALIDATION_SCHEMA = object().shape({
  customerName: REQUIRED_VALIDATION,
  email: EMAIL_VALIDATION,
  contactName: NOT_REQUIRED_VALIDATION,
  tin: string().when('type', {
    is: InvoiceCustomerType.COMPANY,
    then: REQUIRED_VALIDATION,
    otherwise: NOT_REQUIRED_VALIDATION,
  }),
});

export const CUSTOMER_ADDRESS_FORM_VALIDATION_SCHEMA = object().shape({
  country: COUNTRY_VALIDATION,
  city: INVOICE_TOWN_OR_CITY_VALIDATION,
  address: INVOICE_ADDRESS_LINE_VALIDATION,
  postCode: POST_CODE_VALIDATION,
});

export const FORM_VALIDATION_SCHEMA = CUSTOMER_INFORMATION_FORM_VALIDATION_SCHEMA.concat(
  CUSTOMER_ADDRESS_FORM_VALIDATION_SCHEMA
);
