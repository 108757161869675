import React from 'react';

import { FieldCountrySelect, FieldTextInput } from 'components/v2/Fields';

import { TInvoiceCustomerAddressFieldsProps } from '../InvoiceCustomerForm.types';

function InvoiceCustomerAddressFields({ control, errors }: TInvoiceCustomerAddressFieldsProps) {
  return (
    <>
      <FieldCountrySelect
        name="country"
        labelId="label.countryOfCustomer"
        control={control}
        errors={errors}
      />
      <FieldTextInput
        name="city"
        control={control}
        errors={errors}
        labelId="label.city"
        placeholderId="placeholder.empty"
        maxLength={45}
      />
      <FieldTextInput
        name="address"
        control={control}
        errors={errors}
        labelId="label.addressLine"
        placeholderId="placeholder.empty"
        maxLength={250}
      />
      <FieldTextInput
        name="postCode"
        control={control}
        errors={errors}
        labelId="label.postCode"
        placeholderId="placeholder.empty"
        autoCapitalize="characters"
        maxLength={20}
        marginBottom="xxl"
      />
    </>
  );
}

export default InvoiceCustomerAddressFields;
