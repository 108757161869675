import React from 'react';

import { FieldPhoneNumber, FieldTextInput } from 'components/v2/Fields';
import { BaseForm, useForm } from 'components/v2/Forms';
import PageHeader from 'components/v2/PageHeader';
import SectionHeaderTitle from 'components/v2/SectionHeaderTitle';
import InvoiceItemCurrencySelectButton from 'components/v2/InvoiceItemCurrencySelectButton';

import { TCurrency } from 'types/common';

import {
  TInvoiceSettingsDefaultProps,
  TInvoiceSettingsDefaultFormData,
} from './InvoiceSettingsDefaultForm.types';
import { FORM_VALIDATION_SCHEMA } from './InvoiceSettingsDefaultForm.constants';
import InvoicePrefixView from './views/InvoicePrefixView';

function InvoiceSettingsDefaultForm({
  onSubmit,
  loading,
  defaultValues,
  submitButtonLabelId = 'button.save',
  hasMultipleCurrencies,
}: TInvoiceSettingsDefaultProps) {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
    trigger,
    setValue,
    watch,
  } = useForm<TInvoiceSettingsDefaultFormData>({
    schema: FORM_VALIDATION_SCHEMA,
    defaultValues,
    context: {
      nextInvoiceNumber: defaultValues?.nextInvoiceNumber,
    },
  });
  const defaultCurrency = watch('defaultCurrency');
  const invoiceNumberPrefix = watch('invoiceNumberPrefix');
  const nextInvoiceNumber = watch('nextInvoiceNumber');

  return (
    <BaseForm
      onSubmit={handleSubmit(onSubmit)}
      submitButtonLabelId={submitButtonLabelId}
      submitButtonProps={{ loading }}>
      <PageHeader
        titleId="label.defaultSettings"
        descriptionId="screens.invoiceSettingsDefault.description"
      />
      <FieldTextInput
        labelId="label.invoicePrefix"
        placeholderId="placeholder.empty"
        name="invoiceNumberPrefix"
        control={control}
        errors={errors}
        maxLength={20}
        autoCapitalize="characters"
      />
      <FieldTextInput
        labelId="label.invoiceNumber"
        placeholderId="placeholder.empty"
        name="nextInvoiceNumber"
        control={control}
        errors={errors}
      />
      <InvoicePrefixView
        invoiceNumberPrefix={invoiceNumberPrefix}
        invoiceNumber={nextInvoiceNumber}
      />
      <SectionHeaderTitle titleId="label.contactDetails" mb="s" />
      <FieldPhoneNumber
        control={control}
        errors={errors}
        name="contactPhoneNumber"
        trigger={trigger}
        isFormSubmitted={isSubmitted}
      />
      <FieldTextInput
        control={control}
        errors={errors}
        name="contactEmail"
        labelId="label.email"
        placeholderId="placeholder.email"
        autoCapitalize="none"
        autoComplete="email"
      />
      <SectionHeaderTitle titleId="label.itemCurrency" mb="s" />
      <InvoiceItemCurrencySelectButton
        currency={defaultCurrency}
        onSelect={(currency: TCurrency) => setValue('defaultCurrency', currency)}
        disabled={!hasMultipleCurrencies}
      />
    </BaseForm>
  );
}

export default InvoiceSettingsDefaultForm;
