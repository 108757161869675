import React from 'react';

import { AlertOctagonIcon } from 'components/v2/Icons';
import Text from 'components/v2/core/Text';
import Box from 'components/core/Box';

import { TAmountInputErrorLabelProps } from '../AmountInput.types';

function AmountInputErrorLabel(props: TAmountInputErrorLabelProps) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" mt="s">
      <AlertOctagonIcon width={16} height={16} iconColor="statusErrorPrimary" />
      <Text {...props} color="statusErrorPrimary" variant="textBodySmallBold" ml="xs" />
    </Box>
  );
}

export default AmountInputErrorLabel;
