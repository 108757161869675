import React from 'react';

import Box from 'components/core/Box';
import { XCloseIcon } from 'components/v2/Icons';

import NavigationHeaderBaseButton from './views/NavigationHeaderBaseButton';
import { TNavigationHeaderCloseButtonProps } from './Navigation.types';

function NavigationHeaderCloseButton({ onPress }: TNavigationHeaderCloseButtonProps) {
  return (
    <NavigationHeaderBaseButton onPress={onPress}>
      <Box width={24} height={24}>
        <XCloseIcon width={24} height={24} />
      </Box>
    </NavigationHeaderBaseButton>
  );
}

export default NavigationHeaderCloseButton;
