import React, { memo, useRef } from 'react';

import CardViewPinAuthenticationModal from 'modals/CardViewPinAuthenticationModal';
import PopconfirmModal from 'modals/PopconfirmModal';
import CardDetailSettingsModal from 'modals/CardDetailSettingsModal';
import CardInfoAuthenticationModal from 'modals/CardInfoAuthenticationModal';

import { TPopconfirmModalRef } from 'modals/PopconfirmModal/PopconfirmModal.types';
import { isCardFrozen } from 'enums/CardStatus';
import { TCardDetailSettingsModalRef } from 'modals/CardDetailSettingsModal/CardDetailSettingsModal.types';
import { TCardInfoAuthenticationModalRef } from 'modals/CardInfoAuthenticationModal/CardInfoAuthenticationModal.types';
import { TCardViewPinAuthenticationModalRef } from 'modals/CardViewPinAuthenticationModal/CardViewPinAuthenticationModal.types';

import { TCardDetailContentProps } from '../CardDetail.types';
import { useCardStatusUpdate } from '../CardDetail.hooks';
import CardDetailSummary from './CardDetailSummary';

function CardDetailContent({
  card,
  onRequestCardDetail,
  spendingLimits,
  retrieveCardDetailSpendingLimitsLoading,
  retrieveCardDetailSpendingLimits,
}: TCardDetailContentProps) {
  const popconfirmModalRef = useRef<TPopconfirmModalRef>(null);
  const cardViewPinAuthenticationModalRef = useRef<TCardViewPinAuthenticationModalRef>(null);
  const cardDetailSettingsModalRef = useRef<TCardDetailSettingsModalRef>(null);
  const cardInfoAuthenticationModalRef = useRef<TCardInfoAuthenticationModalRef>(null);

  const { terminateShowPopconfirmState, unblockPinShowPopconfirmState } = useCardStatusUpdate({
    card,
    onRequestCardDetail,
  });

  const isFrozen = isCardFrozen(card.status);

  function handleSelectCardInfo() {
    cardInfoAuthenticationModalRef.current?.show({ card });
  }

  function handleClickShowPin() {
    cardViewPinAuthenticationModalRef.current?.show({ card });
  }

  function handleClickTerminate() {
    popconfirmModalRef.current?.show(terminateShowPopconfirmState);
  }

  function handleClickUnblockPin() {
    popconfirmModalRef.current?.show(unblockPinShowPopconfirmState);
  }

  function handleShowSettingsOnClick() {
    cardDetailSettingsModalRef.current?.show({
      card,
    });
  }

  return (
    <>
      <CardDetailSummary
        card={card}
        isFrozen={isFrozen}
        onShowCardPinClick={handleClickShowPin}
        onShowSettingsClick={handleShowSettingsOnClick}
        onCardInfoClick={handleSelectCardInfo}
        onRequestCardDetail={onRequestCardDetail}
        spendingLimits={spendingLimits}
        retrieveCardDetailSpendingLimitsLoading={retrieveCardDetailSpendingLimitsLoading}
        onCardSpendLimitUpdate={retrieveCardDetailSpendingLimits}
      />
      <CardViewPinAuthenticationModal modalRef={cardViewPinAuthenticationModalRef} />
      <CardInfoAuthenticationModal modalRef={cardInfoAuthenticationModalRef} />
      <CardDetailSettingsModal
        modalRef={cardDetailSettingsModalRef}
        card={card}
        onShowCardPinClick={handleClickShowPin}
        onCardUnblockPinClick={handleClickUnblockPin}
        onCardTerminateClick={handleClickTerminate}
        spendingLimits={spendingLimits}
        retrieveCardDetailSpendingLimits={retrieveCardDetailSpendingLimits}
      />
      <PopconfirmModal ref={popconfirmModalRef} />
    </>
  );
}

export default memo(CardDetailContent);
