import spacing from 'theme/spacing';
import { textVariants } from 'theme/v2/variants';
import borderRadius from 'theme/borderRadius';

export const NUMBER_INPUT_INPUT_BORDER_RADIUS = borderRadius.s;
export const NUMBER_INPUT_FOCUSED_LABEL_HEIGHT = 16;
export const NUMBER_INPUT_FOCUSED_LABEL_TEXT_SPACING = spacing.xxs;
export const NUMBER_INPUT_PADDING_VERTICAL_SPACING = spacing.sm;
export const NUMBER_INPUT_INPUT_FONT = textVariants.textBodySubBold;
export const ICON_SIZE = 24;
