import React, { useState } from 'react';
import { getOr, isNil } from 'lodash/fp';

import Card from 'components/v2/core/Card';
import Tag from 'components/v2/core/Tag';
import Box from 'components/core/Box';
import { FieldNumberInput, FieldTextInput } from 'components/v2/Fields';
import { Percent02Icon } from 'components/v2/Icons';
import Text from 'components/v2/core/Text';
import FormErrorLabel from 'components/v2/core/FormErrorLabel';

import VatRate from 'enums/VatRate';
import { TI18nId } from 'types/common';

import { TVatRateFieldsProps } from '../InvoiceSellItemForm.types';

function VatRateFields({ control, errors, trigger, vatRate, onSetVatRate }: TVatRateFieldsProps) {
  const [isCustomVatRate, setIsCustomVatRate] = useState<boolean>(false);
  const errorMessageId = getOr(undefined, 'vat.rate', errors)?.message;

  function handleEnterCustomVatRate() {
    onSetVatRate(undefined);
    setIsCustomVatRate(true);
  }

  function handleSelectVatRate(rate: VatRate) {
    setIsCustomVatRate(false);
    onSetVatRate(rate);
    trigger('vat.title');
    trigger('vat.rate');
  }

  return (
    <Box mb="m">
      <Text
        textId="label.vatRate"
        variant="textBodySubBold"
        mb="s"
        color={errorMessageId ? 'statusErrorPrimary' : 'textPrimary'}
      />
      <Card p="m" gap="m">
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap="sm">
          <Tag
            labelId="label.rate.withPercentage"
            labelValues={{
              rate: VatRate.ZERO,
            }}
            selected={!isCustomVatRate && vatRate === VatRate.ZERO}
            onClick={() => handleSelectVatRate(VatRate.ZERO)}
          />
          <Tag
            labelId="label.rate.withPercentage"
            labelValues={{
              rate: VatRate.TEN,
            }}
            selected={!isCustomVatRate && vatRate === VatRate.TEN}
            onClick={() => handleSelectVatRate(VatRate.TEN)}
          />
          <Tag
            labelId="label.rate.withPercentage"
            labelValues={{
              rate: VatRate.TWENTY,
            }}
            selected={!isCustomVatRate && vatRate === VatRate.TWENTY}
            onClick={() => handleSelectVatRate(VatRate.TWENTY)}
          />
          <Tag
            labelId="label.custom"
            selected={isCustomVatRate}
            onClick={handleEnterCustomVatRate}
          />
        </Box>
        {isCustomVatRate && (
          <FieldNumberInput
            labelId="label.vatRate"
            placeholderId="placeholder.empty"
            name="vat.rate"
            control={control}
            errors={errors}
            rightIcon={Percent02Icon}
            marginBottom="none"
            decimalScale={2}
            isAllowed={(values: any) => {
              const { floatValue } = values;
              return !floatValue || floatValue <= 100;
            }}
          />
        )}
        {(!isNil(vatRate) || isCustomVatRate) && (
          <FieldTextInput
            name="vat.title"
            labelId="label.vatTitle"
            placeholderId="placeholder.five"
            control={control}
            errors={errors}
            marginBottom="none"
            maxLength={128}
          />
        )}
      </Card>
      {!isCustomVatRate && errorMessageId && <FormErrorLabel textId={errorMessageId as TI18nId} />}
    </Box>
  );
}

export default VatRateFields;
/*
<FieldTextInput
          name="vat.rate"
          labelId="label.vatRate"
          placeholderId="placeholder.empty"
          control={control}
          errors={errors}
        />
*/
