import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { ImagePlusIcon } from 'components/v2/Icons';
import ListItemBase from 'components/v2/core/ListItemBase';
import Card from 'components/v2/core/Card';
import Avatar from 'components/v2/core/Avatar';
import IconBox from 'components/v2/core/IconBox';

import { TListItemBaseStates } from 'components/v2/core/ListItemBase/ListItemBase.types';
import NOOP from 'constants/noop';

import { TBusinessLogoProps } from '../InvoiceSettingsBusinessDetailsForm.types';

function BusinessLogo({ onSelect, uri }: TBusinessLogoProps) {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: ['image/jpg', 'image/jpeg', 'image/png'],
    maxFiles: 1,
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      onSelect(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  function renderRightComponent({ pressed }: TListItemBaseStates) {
    if (uri) {
      return <Avatar imageUrl={uri} size="medium" imageResizeMode="cover" />;
    }
    return (
      <IconBox
        icon={ImagePlusIcon}
        backgroundColor={pressed ? 'buttonGhostPrimaryHover' : 'backgroundPrimary'}
      />
    );
  }

  return (
    <Card p="s" {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      <ListItemBase
        labelId={uri ? 'label.logo' : 'label.addYourLogo'}
        descriptionId="messages.invoiceAddLogo.description"
        right={renderRightComponent}
        onPress={NOOP}
      />
    </Card>
  );
}

export default BusinessLogo;
