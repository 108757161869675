import {
  TTextLinkGetStateStyleProps,
  TTextLinkGetStyleReturn,
  TTextLinkGetSizeProps,
  TTextLinkSizeProperty,
} from './TextLink.types';
import { TEXT_LINK_SIZE_MAP, TEXT_LINK_STATE_MAP } from './TextLink.constants';

export function getTextLinkStateStyle({
  pressed,
  disabled,
  loading,
}: TTextLinkGetStateStyleProps): TTextLinkGetStyleReturn {
  if (disabled || loading) {
    return TEXT_LINK_STATE_MAP.disabled;
  }
  if (pressed) {
    return TEXT_LINK_STATE_MAP.onTap;
  }
  return TEXT_LINK_STATE_MAP.default;
}

export function getTextLinkSize({ size }: TTextLinkGetSizeProps): TTextLinkSizeProperty {
  return TEXT_LINK_SIZE_MAP[size];
}
