import React from 'react';

import Shimmer from 'components/core/Shimmer';
import Box from 'components/core/Box';
import { TextShimmer } from 'components/v2/core/Text';

import { TEXT_INPUT_BASE_INPUT_BORDER_RADIUS } from 'components/v2/core/TextInputBase/TextInputBase.constants';

import { TEXT_AREA_INPUT_HEIGHT } from '../TextAreaInput.constants';

function TextAreaInputShimmer() {
  return (
    <Box width="100%">
      <Shimmer
        shape="line"
        width="100%"
        height={TEXT_AREA_INPUT_HEIGHT}
        borderRadius={TEXT_INPUT_BASE_INPUT_BORDER_RADIUS}
      />
      <Box mt="s" width="100%" display="flex" alignItems="center" justifyContent="flex-end">
        <TextShimmer width="15%" variant="textBodySub" />
      </Box>
    </Box>
  );
}

export default TextAreaInputShimmer;
