import React from 'react';

import Text from 'components/v2/core/Text';
import Box from 'components/core/Box';

import { getIconByCurrency } from 'enums/Currency';

import { TAmountInputCurrencyFlagProps } from '../AmountInput.types';

function AmountInputCurrencyFlag({ currencyCode }: TAmountInputCurrencyFlagProps) {
  const CurrencyIcon = getIconByCurrency(currencyCode);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" minHeight={32}>
      <Text variant="textBodyBold" mr="xs">
        {currencyCode}
      </Text>
      <CurrencyIcon width={20} height={20} />
    </Box>
  );
}

export default AmountInputCurrencyFlag;
