import { object, string } from 'yup';

import { NOT_REQUIRED_VALIDATION, REQUIRED_VALIDATION } from 'constants/validation';

export const FORM_VALIDATION_SCHEMA = object().shape({
  merchantVatNumber: string().when('$isVatNumberFieldVisible', {
    is: (isVatNumberFieldVisible: boolean) => isVatNumberFieldVisible,
    then: REQUIRED_VALIDATION,
    otherwise: NOT_REQUIRED_VALIDATION,
  }),
});
