import React from 'react';

import SwitchButtonItem from 'components/v2/core/SwitchButtonItem';
import Card from 'components/v2/core/Card';
import ListItem from 'components/v2/core/ListItem';
import Box from 'components/core/Box';
import { Compass02Icon } from 'components/v2/Icons';

import { TMerchantVatNumberItemProps } from '../InvoiceSettingsBusinessDetailsForm.types';

function MerchantVatNumberItem({
  value,
  onChange,
  renderFieldComponent,
}: TMerchantVatNumberItemProps) {
  return (
    <Card p="s">
      <ListItem
        labelId="label.vatNumber"
        descriptionId="messages.invoiceVatNumber.description"
        icon={Compass02Icon}
        iconColor="textActive"
      />
      <SwitchButtonItem
        name="vatRegistered"
        textId="messages.businessIsVatRegistered.title"
        value={value}
        onChange={() => onChange(!value)}
      />
      {value && (
        <Box px="s" mt="s">
          {renderFieldComponent()}
        </Box>
      )}
    </Card>
  );
}

export default MerchantVatNumberItem;
