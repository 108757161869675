import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoCircleIcon } from 'components/v2/Icons';
import Text from 'components/v2/core/Text';
import Box from 'components/core/Box';

import { formatAmount } from 'helpers/formatAmount';

import { TAmountInputFeeProps } from '../AmountInput.types';
import { StyledFeeButton } from '../AmountInput.styles';

function AmountInputFee({ fee, onPressFee }: TAmountInputFeeProps) {
  const { t } = useTranslation();
  const hasFee = fee?.amount && fee.amount > 0;

  function getFeeAmount() {
    if (hasFee) {
      return formatAmount({ amount: fee.amount, currency: fee.currency });
    }
    return t('label.free');
  }

  return (
    <StyledFeeButton onClick={onPressFee}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <InfoCircleIcon width={16} height={16} iconColor="textPassive" />
        <Box display="flex" flexDirection="row" alignItems="center">
          <Text variant="textBodySmallBold" ml="xs">
            {getFeeAmount()}
          </Text>
          {hasFee && (
            <Text
              textId="label.amountInputFeeLabel"
              variant="textBodySmall"
              color="textSecondary"
              ml="xxs"
            />
          )}
        </Box>
      </Box>
    </StyledFeeButton>
  );
}

export default AmountInputFee;
