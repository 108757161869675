import { TOption } from 'components/v2/core/RadioGroup/RadioGroup.types';
import CardSpendingLimitType, {
  getLabelIdByCardSpendingLimitType,
} from 'enums/CardSpendingLimitType';

export const CARD_SPENDING_LIMIT_TYPE_OPTIONS: TOption<CardSpendingLimitType>[] = [
  {
    label: getLabelIdByCardSpendingLimitType(CardSpendingLimitType.DAILY),
    value: CardSpendingLimitType.DAILY,
  },
  {
    label: getLabelIdByCardSpendingLimitType(CardSpendingLimitType.WEEKLY),
    value: CardSpendingLimitType.WEEKLY,
  },
  {
    label: getLabelIdByCardSpendingLimitType(CardSpendingLimitType.MONTHLY),
    value: CardSpendingLimitType.MONTHLY,
  },
];
