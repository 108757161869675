import React, { useRef } from 'react';
import { Controller, UseControllerReturn } from 'react-hook-form';
import { getOr } from 'lodash/fp';

import Box from 'components/core/Box';
import SelectButton from 'components/v2/core/SelectButton';
import { Globe04Icon } from 'components/v2/Icons';
import FormErrorLabel from 'components/v2/core/FormErrorLabel';
import CountryImage from 'components/CountryImage';
import SelectCountryModal from 'modals/SelectCountryModal';

import { TCountry, TI18nId } from 'types/common';
import { useCountriesContext } from 'context/CountriesProvider';
import { TSelectCountryModalRef } from 'modals/SelectCountryModal/SelectCountryModal.types';

import { TFieldCountrySelectProps } from './FieldCountrySelect.types';

function FieldCountrySelect({
  name,
  labelId,
  control,
  errors,
  marginBottom = 'm',
  defaultValue,
  disabled,
}: TFieldCountrySelectProps) {
  const errorMessageId = getOr(undefined, name, errors)?.code?.message;
  const modalRef = useRef<TSelectCountryModalRef>(null);
  const { countriesState } = useCountriesContext();

  function renderCountryIcon(countryCode: TCountry['code']) {
    if (countryCode) {
      return <CountryImage code={countryCode} size={24} />;
    }
    return <Globe04Icon width={24} height={24} />;
  }

  function handleRender({ field }: UseControllerReturn) {
    const { onChange, value } = field;

    function handleOnPress() {
      modalRef.current?.show({
        onSelect: onChange,
        headerTitleId: 'label.selectCountry',
      });
    }

    return (
      <SelectButton
        labelId={labelId}
        value={value?.name}
        hasError={!!errorMessageId}
        icon={renderCountryIcon(value?.code)}
        onClick={handleOnPress}
        disabled={disabled}
      />
    );
  }

  return (
    <>
      <Box marginBottom={marginBottom}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={handleRender}
        />
        {errorMessageId && <FormErrorLabel textId={errorMessageId as TI18nId} />}
      </Box>
      <SelectCountryModal
        modalRef={modalRef}
        options={countriesState.countries}
        showCallingCode={false}
      />
    </>
  );
}

export default FieldCountrySelect;
