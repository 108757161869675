import React from 'react';

import Shimmer from 'components/core/Shimmer/Shimmer';

import { TAG_MIN_HEIGHT, TAG_BORDER_RADIUS } from '../Tag.constants';
import { TTagShimmerProps } from '../Tag.types';

function TagShimmer({ closable }: TTagShimmerProps) {
  return (
    <Shimmer
      shape="line"
      width={closable ? '30%' : '20%'}
      height={TAG_MIN_HEIGHT}
      borderRadius={TAG_BORDER_RADIUS}
    />
  );
}

export default TagShimmer;
