import React from 'react';

import { BaseForm, useForm } from 'components/v2/Forms';
import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import { FieldSegmentedControl } from 'components/v2/Fields';

import { TSegmentedControlOption } from 'components/v2/core/SegmentedControl/SegmentedControl.types';
import InvoiceCustomerType, { getInvoiceCustomerTypeOptions } from 'enums/InvoiceCustomerType';

import { FORM_VALIDATION_SCHEMA } from './InvoiceCustomerForm.constants';
import { TInvoiceCustomerFormProps, TInvoiceCustomerFormData } from './InvoiceCustomerForm.types';
import InvoiceCustomerInformationFields from './views/InvoiceCustomerInformationFields';
import InvoiceCustomerAddressFields from './views/InvoiceCustomerAddressFields';

function InvoiceCustomerForm({
  submitButtonLabelId,
  onSubmit,
  submitButtonProps,
  defaultValues,
  loading,
  onWatchValues,
}: TInvoiceCustomerFormProps) {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<TInvoiceCustomerFormData>({
    schema: FORM_VALIDATION_SCHEMA,
    defaultValues,
  });
  const invoiceCustomerType = watch('type');

  React.useEffect(() => {
    if (onWatchValues) {
      const subscription = watch((value) => onWatchValues(value as TInvoiceCustomerFormData));
      return () => subscription.unsubscribe();
    }
    return undefined;
  }, [watch]);

  return (
    <BaseForm
      onSubmit={handleSubmit(onSubmit)}
      submitButtonLabelId={submitButtonLabelId}
      submitButtonProps={{ loading, ...submitButtonProps }}>
      <Box mb="m">
        <FieldSegmentedControl<InvoiceCustomerType>
          name="type"
          control={control}
          options={
            getInvoiceCustomerTypeOptions() as TSegmentedControlOption<InvoiceCustomerType>[]
          }
        />
      </Box>
      <Text textId="label.contactInformation" variant="textBodySubBold" mb="s" />
      <InvoiceCustomerInformationFields
        control={control}
        errors={errors}
        invoiceCustomerType={invoiceCustomerType}
      />
      <Text textId="label.addressInformation" variant="textBodySubBold" my="s" />
      <InvoiceCustomerAddressFields control={control} errors={errors} />
    </BaseForm>
  );
}

export default InvoiceCustomerForm;
