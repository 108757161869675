import { object, string } from 'yup';

import InvoiceItemDiscountType from 'enums/InvoiceItemDiscountType';
import {
  REQUIRED_VALIDATION,
  NOT_REQUIRED_VALIDATION,
  REQUIRED_OBJECT_VALIDATION,
} from 'constants/validation';

export const FORM_VALIDATION_SCHEMA = object().shape({
  id: string().notRequired(),
  title: REQUIRED_VALIDATION,
  vat: REQUIRED_OBJECT_VALIDATION.shape({
    title: REQUIRED_VALIDATION,
    rate: REQUIRED_VALIDATION,
  }),
  description: NOT_REQUIRED_VALIDATION,
  unitType: REQUIRED_VALIDATION,
  quantity: string().when('$isQuantityFieldVisible', {
    is: (isQuantityFieldVisible: boolean) => isQuantityFieldVisible,
    then: string().trim().required('placeholder.empty'),
    otherwise: string().notRequired(),
  }),
  unitPrice: REQUIRED_VALIDATION,
  discount: object()
    .notRequired()
    .test((value, { options, createError }) => {
      // @ts-ignore
      const unitPrice = options?.parent?.unitPrice;

      if (
        value?.type === InvoiceItemDiscountType.ABSOLUTE &&
        unitPrice &&
        value?.amount &&
        Number(value.amount) > Number(unitPrice)
      ) {
        return createError({
          path: 'discount.amount',
          message: 'validation.discountAmount.max',
        });
      }
      return true;
    }),
});
