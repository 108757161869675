import { object } from 'yup';

import { REQUIRED_VALIDATION } from 'constants/validation';

export const FORM_VALIDATION_SCHEMA = object().shape({
  shareInvoiceEmailTitle: REQUIRED_VALIDATION,
  shareInvoiceEmailBody: REQUIRED_VALIDATION,
});

export const TITLE_MAX_LENGTH = 255;
export const MAIL_BODY_MAX_LENGTH = 1024;
export const TEXT_AREA_HEIGHT = 218;
