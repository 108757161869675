import { Variants } from 'framer-motion';
import { object } from 'yup';

import { NOT_REQUIRED_VALIDATION } from 'constants/validation';

export const INPUT_ANIMATION_VARIANTS: Variants = {
  enter: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  default: { opacity: 0, y: -10 },
};

export const CARD_SPEND_LIMIT_FORM_VALIDATION_SCHEMA = object().shape({
  cardSpendingLimitType: NOT_REQUIRED_VALIDATION,
  spendLimit: NOT_REQUIRED_VALIDATION,
  singleTransactionLimit: NOT_REQUIRED_VALIDATION,
});

export const MAX_AMOUNT_INPUT_VALUE = 10000000;
