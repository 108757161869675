import React, { useRef } from 'react';
import { NumberFormatPropsBase } from 'react-number-format';

import Box from 'components/core/Box';
import AmountInputBase from 'components/v2/core/AmountInputBase';

import AmountInputFee from './views/AmountInputFee';
import AmountInputCurrencyFlag from './views/AmountInputCurrencyFlag';
import AmountInputConversionRate from './views/AmountInputConversionRate';
import AmountInputErrorLabel from './views/AmountInputErrorLabel';

import { TAmountInputProps } from './AmountInput.types';

function AmountInput({
  id = 'amount',
  currency,
  conversionInfo,
  fee,
  onPressFee,
  hasError = false,
  errorLabelProps,
  onValueChange,
  isBottomContentVisible = true,
  value,
  inputHeight = 72,
  maxLength,
  autoFocus,
  hasCurrencyIcon = true,
  isAllowed,
}: TAmountInputProps) {
  const inputRef = useRef<NumberFormatPropsBase<number>>(null);

  function renderBottomContent() {
    if (isBottomContentVisible) {
      if (errorLabelProps && hasError) {
        return <AmountInputErrorLabel {...errorLabelProps} />;
      }
      return <AmountInputFee fee={fee} onPressFee={onPressFee} />;
    }

    return <></>;
  }

  return (
    <AmountInputBase
      id={id}
      ref={inputRef}
      currency={currency}
      onValueChange={onValueChange}
      hasError={hasError}
      height={inputHeight}
      value={value}
      amountBottomContent={renderBottomContent}
      maxLength={maxLength}
      autoFocus={autoFocus}
      isAllowed={isAllowed}
      rightContent={() =>
        hasCurrencyIcon &&
        currency && (
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <AmountInputCurrencyFlag currencyCode={currency.code} />
            {conversionInfo && <AmountInputConversionRate conversionInfo={conversionInfo} />}
          </Box>
        )
      }
    />
  );
}

export default AmountInput;
