import { TThemePrimitives } from 'theme/v2/theme.types';

import { TGetLabelTextColorParams } from './SelectButton.types';

export function getLabelTextColor({
  hasValue,
  disabled,
  hasError,
}: TGetLabelTextColorParams): keyof TThemePrimitives {
  if (disabled) {
    return 'textPassive';
  }

  if (hasError) {
    return 'statusErrorPrimary';
  }

  if (hasValue) {
    return 'textSecondary';
  }

  return 'textPrimary';
}
