import React, { useEffect, useState } from 'react';

import { BaseForm, useForm } from 'components/v2/Forms';
import PageHeader from 'components/v2/PageHeader';
import Box from 'components/core/Box';
import { FieldTextInput } from 'components/v2/Fields';

import BusinessLogo from './views/BusinessLogo';
import MerchantVatNumberItem from './views/MerchantVatNumberItem';

import {
  TInvoiceSettingsBusinessDetailsFormProps,
  TInvoiceSettingsBusinessDetailsFormData,
} from './InvoiceSettingsBusinessDetailsForm.types';
import { FORM_VALIDATION_SCHEMA } from './InvoiceSettingsBusinessDetailsForm.constants';

function InvoiceSettingsBusinessDetailsForm({
  onSubmit,
  defaultValues,
  loading,
  submitButtonLabelId = 'button.save',
  merchantLogoLink,
}: TInvoiceSettingsBusinessDetailsFormProps) {
  const [isVatNumberFieldVisible, setIsVatNumberFieldVisible] = useState<boolean>(
    !!defaultValues?.merchantVatNumber
  );
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    unregister,
    formState: { errors },
  } = useForm<TInvoiceSettingsBusinessDetailsFormData>({
    schema: FORM_VALIDATION_SCHEMA,
    defaultValues,
    context: {
      isVatNumberFieldVisible,
    },
  });
  const logo = watch('merchantLogo');

  useEffect(() => {
    if (!isVatNumberFieldVisible) {
      unregister('merchantVatNumber');
    }
  }, [isVatNumberFieldVisible]);

  function getLogoPath(): string | undefined {
    if (logo) {
      return URL.createObjectURL(logo);
    }
    return undefined;
  }

  return (
    <BaseForm
      onSubmit={handleSubmit(onSubmit)}
      submitButtonLabelId={submitButtonLabelId}
      submitButtonProps={{
        loading,
      }}>
      <PageHeader
        titleId="label.businessDetails"
        descriptionId="screens.invoiceBusinessDetails.description"
      />
      <Box display="flex" flexDirection="column" mb="m" gap="m">
        <BusinessLogo
          onSelect={(document) => setValue('merchantLogo', document)}
          uri={getLogoPath() || merchantLogoLink}
        />
        <MerchantVatNumberItem
          value={isVatNumberFieldVisible}
          onChange={setIsVatNumberFieldVisible}
          renderFieldComponent={() => (
            <FieldTextInput
              control={control}
              errors={errors}
              name="merchantVatNumber"
              labelId="label.vatNumber"
              placeholderId="placeholder.empty"
              autoCapitalize="characters"
            />
          )}
        />
      </Box>
    </BaseForm>
  );
}

export default InvoiceSettingsBusinessDetailsForm;
