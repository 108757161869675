import React, { useMemo, useState } from 'react';

import Modal from 'components/core/Modal';
import Card from 'components/core/Card';
import Box from 'components/core/Box';
import RadioGroup from 'components/v2/core/RadioGroup';
import Button from 'components/v2/core/Button';

import InvoiceItemDiscountType, {
  getIconByInvoiceItemDiscountType,
  getUnitTypeRadioGroup,
} from 'enums/InvoiceItemDiscountType';

import { TInvoiceSellItemDiscountTypeSelectModalProps } from './InvoiceSellItemDiscountTypeSelectModal.types';

function InvoiceSellItemDiscountTypeSelectModal({
  modalRef,
  discountType,
  onSelectDiscountType,
}: TInvoiceSellItemDiscountTypeSelectModalProps) {
  const [selectedDiscountType, setSelectDiscountType] =
    useState<InvoiceItemDiscountType>(discountType);
  const options = useMemo(() => getUnitTypeRadioGroup(), []);

  function handleConfirm() {
    onSelectDiscountType(selectedDiscountType);
    modalRef.current?.show(false);
  }

  return (
    <Modal ref={modalRef} header={{ id: 'label.discountType' }}>
      <Card p="s" mb="m">
        <RadioGroup<InvoiceItemDiscountType>
          id="discountType"
          options={options}
          value={selectedDiscountType}
          onChange={(option) => setSelectDiscountType(option.value)}
          renderOptionItem={(option) => ({
            id: option.value,
            icon: getIconByInvoiceItemDiscountType(option.value),
          })}
        />
      </Card>
      <Box display="flex" flexDirection="column" gap="m">
        <Button
          labelId="button.cancel"
          variant="secondary"
          onClick={() => modalRef.current?.show(false)}
        />
        <Button labelId="button.save" onClick={handleConfirm} />
      </Box>
    </Modal>
  );
}

export default InvoiceSellItemDiscountTypeSelectModal;
