import React, { memo } from 'react';

import RadioItem from 'components/v2/core/RadioItem';

import { TI18nId } from 'types/common';

import { TRadioGroupProps } from './RadioGroup.types';

function RadioGroup<T>({
  id = 'radio',
  options = [],
  value,
  disabled,
  onChange,
  renderOptionItem,
}: TRadioGroupProps<T>) {
  return (
    <>
      {options.map((option, i) => (
        <RadioItem
          id={id}
          key={i}
          labelId={option.label as TI18nId}
          checked={option.value === value}
          marginBottom={i === options.length - 1 ? 'none' : 's'}
          {...renderOptionItem?.(option)}
          onPress={() => onChange(option)}
          disabled={disabled}
        />
      ))}
    </>
  );
}

export default memo(RadioGroup) as typeof RadioGroup;
