import { TAG_STATE_STYLE_MAP } from 'components/v2/core/Tag/Tag.constants';

import { TTagGetStyleProps, TTagGetStyleReturn } from './Tag.types';

export function getTagStyle({ pressed, selected }: TTagGetStyleProps): TTagGetStyleReturn {
  if (pressed) {
    return TAG_STATE_STYLE_MAP.onTap;
  }
  if (selected) {
    return TAG_STATE_STYLE_MAP.selected;
  }
  return TAG_STATE_STYLE_MAP.default;
}
