import React from 'react';

import InfoItem from 'components/v2/core/InfoItem';
import Card from 'components/v2/core/Card';
import Text from 'components/v2/core/Text';
import Box from 'components/core/Box';
import Pressable from 'components/v2/core/Pressable';

import InvoiceItemCurrencySelectModal from 'modals/InvoiceItemCurrencySelectModal';

import { useModal } from 'components/core/Modal';
import { getStateStyle } from 'theme/v2/themeMapByState';
import { getIconByCurrency } from 'enums/Currency';

import { TInvoiceItemCurrencySelectButtonProps } from './InvoiceItemCurrencySelectButton.types';

function InvoiceItemCurrencySelectButton({
  currency,
  onSelect,
  disabled,
}: TInvoiceItemCurrencySelectButtonProps) {
  const invoiceItemCurrencySelectModalRef = useModal();

  const CurrencyIcon = getIconByCurrency(currency.code);

  return (
    <>
      <Card pointerEvents={disabled ? 'none' : 'auto'} p="xs" mb="m">
        <Pressable onClick={() => invoiceItemCurrencySelectModalRef.current?.show(true)}>
          {({ pressed, hovered }) => {
            const stateStyle = getStateStyle({
              pressed,
              hovered,
            });
            return (
              <Box
                p="sm"
                backgroundColor={stateStyle.backgroundColor}
                borderRadius={stateStyle.borderRadius}>
                <InfoItem
                  labelId="label.currency"
                  value={
                    <Box display="flex" flexDirection="row" alignItems="center" gap="xs">
                      <Text variant="textBodySub" color="statusNaturalPrimary">
                        {currency.code}
                      </Text>
                      <CurrencyIcon width={24} height={24} />
                    </Box>
                  }
                />
              </Box>
            );
          }}
        </Pressable>
      </Card>
      <InvoiceItemCurrencySelectModal
        modalRef={invoiceItemCurrencySelectModalRef}
        onSelect={onSelect}
      />
    </>
  );
}

export default InvoiceItemCurrencySelectButton;
