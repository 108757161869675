import React, { useState } from 'react';

import Card from 'components/v2/core/Card';
import Tag from 'components/v2/core/Tag';
import Box from 'components/core/Box';
import { FieldNumberInput, FieldSelect } from 'components/v2/Fields';
import { Percent02Icon } from 'components/v2/Icons';
import Text from 'components/v2/core/Text';

import InvoiceSellItemDiscountTypeSelectModal from 'modals/InvoiceSellItemDiscountTypeSelectModal';
import { useModal } from 'components/core/Modal';
import DiscountPercentage from 'enums/DiscountPercentage';
import InvoiceItemDiscountType, {
  getIconByInvoiceItemDiscountType,
} from 'enums/InvoiceItemDiscountType';

import { TDiscountFieldsProps } from '../InvoiceSellItemForm.types';

function DiscountFields({
  control,
  errors,
  trigger,
  discount,
  onSetDiscountPercentage,
  onSetDiscountType,
  currency,
  defaultValue,
}: TDiscountFieldsProps) {
  const invoiceSellItemDiscountTypeSelectModalRef = useModal();
  const [isCustomDiscount, setIsCustomDiscount] = useState<boolean>(
    isInitialCustomDiscountVisible()
  );

  function isInitialCustomDiscountVisible(): boolean {
    return (
      defaultValue?.type === InvoiceItemDiscountType.ABSOLUTE ||
      !!(
        defaultValue?.percentage &&
        ![DiscountPercentage.FIVE, DiscountPercentage.TEN, DiscountPercentage.TWENTY].includes(
          defaultValue.percentage as DiscountPercentage
        )
      )
    );
  }

  function handleEnterCustomDiscount() {
    setIsCustomDiscount(!isCustomDiscount);
    onSetDiscountPercentage(undefined);
    onSetDiscountType(InvoiceItemDiscountType.PERCENTAGE);
    trigger('discount.percentage');
    trigger('discount.amount');
  }

  function handleSelectDiscountPercentage(percentage: DiscountPercentage) {
    setIsCustomDiscount(false);
    if (discount?.percentage === percentage) {
      onSetDiscountPercentage(undefined);
    } else {
      onSetDiscountPercentage(percentage);
      onSetDiscountType(InvoiceItemDiscountType.PERCENTAGE);
    }
    trigger('discount.percentage');
  }

  function renderDiscountTypeIcon(value: InvoiceItemDiscountType) {
    const Icon = getIconByInvoiceItemDiscountType(value ?? InvoiceItemDiscountType.PERCENTAGE);
    return <Icon />;
  }

  return (
    <>
      <Box mb="m">
        <Text textId="label.addDiscount" variant="textBodySubBold" mb="s" />
        <Card p="m" gap="m">
          <Box display="flex" flexDirection="row" flexWrap="wrap" gap="sm">
            <Tag
              labelId="label.rate.withPercentage"
              labelValues={{
                rate: DiscountPercentage.FIVE,
              }}
              selected={!isCustomDiscount && discount?.percentage === DiscountPercentage.FIVE}
              onClick={() => handleSelectDiscountPercentage(DiscountPercentage.FIVE)}
            />
            <Tag
              labelId="label.rate.withPercentage"
              labelValues={{
                rate: DiscountPercentage.TEN,
              }}
              selected={!isCustomDiscount && discount?.percentage === DiscountPercentage.TEN}
              onClick={() => handleSelectDiscountPercentage(DiscountPercentage.TEN)}
            />
            <Tag
              labelId="label.rate.withPercentage"
              labelValues={{
                rate: DiscountPercentage.TWENTY,
              }}
              selected={!isCustomDiscount && discount?.percentage === DiscountPercentage.TWENTY}
              onClick={() => handleSelectDiscountPercentage(DiscountPercentage.TWENTY)}
            />
            <Tag
              labelId="label.custom"
              selected={isCustomDiscount}
              onClick={handleEnterCustomDiscount}
            />
          </Box>
          {isCustomDiscount && (
            <Box display="flex" flexDirection="row" alignItems="center" gap="m">
              <Box flex={1}>
                {discount?.type === InvoiceItemDiscountType.PERCENTAGE && (
                  <FieldNumberInput
                    name="discount.percentage"
                    labelId="label.discount"
                    placeholderId="placeholder.empty"
                    control={control}
                    errors={errors}
                    marginBottom="none"
                    decimalScale={2}
                    isAllowed={(values: any) => {
                      const { floatValue } = values;
                      return !floatValue || floatValue <= 100;
                    }}
                  />
                )}
                {discount?.type === InvoiceItemDiscountType.ABSOLUTE && (
                  <FieldNumberInput
                    name="discount.amount"
                    labelId="label.discount"
                    placeholderId="placeholder.empty"
                    control={control}
                    errors={errors}
                    marginBottom="none"
                    decimalScale={2}
                    currency={currency}
                  />
                )}
              </Box>
              <FieldSelect<InvoiceItemDiscountType>
                name="discount.type"
                labelId="placeholder.empty"
                control={control}
                errors={errors}
                defaultIcon={Percent02Icon}
                renderIcon={renderDiscountTypeIcon}
                renderValue={(value) => value}
                onPress={() => invoiceSellItemDiscountTypeSelectModalRef.current?.show(true)}
                marginBottom="none"
                isOnlyIconVisible
              />
            </Box>
          )}
        </Card>
      </Box>
      <InvoiceSellItemDiscountTypeSelectModal
        modalRef={invoiceSellItemDiscountTypeSelectModalRef}
        discountType={discount?.type ?? InvoiceItemDiscountType.PERCENTAGE}
        onSelectDiscountType={onSetDiscountType}
      />
    </>
  );
}

export default DiscountFields;
