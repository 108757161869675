import React, { useState, useEffect } from 'react';
import { isNil } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import Box from 'components/core/Box';
import { BaseForm, useForm } from 'components/v2/Forms';
import { FieldAmountTextInput, FieldTextInput, FieldNumberInput } from 'components/v2/Fields';
import TextLink from 'components/v2/core/TextLink';
import { ChevronDownIcon, ChevronUpIcon } from 'components/v2/Icons';
import Button from 'components/v2/core/Button';

import VatRate, { getTitleIdByVatRate } from 'enums/VatRate';
import InvoiceItemDiscountType from 'enums/InvoiceItemDiscountType';

import VatRateFields from './views/VatRateFields';
import DiscountFields from './views/DiscountFields';
import UnitTypeSelectField from './views/UnitTypeSelectField';
import { FORM_VALIDATION_SCHEMA } from './InvoiceSellItemForm.constants';
import {
  TDiscount,
  TInvoiceSellItemFormData,
  TInvoiceSellItemFormProps,
} from './InvoiceSellItemForm.types';

function InvoiceSellItemForm({
  submitButtonLabelId,
  onSubmit,
  submitButtonProps,
  defaultValues,
  loading,
  onDeleteItem,
  onWatchValues,
  onWatchAmountDetailsValues,
  isQuantityFieldVisible = true,
  isDiscountFieldVisible = true,
  focusFieldName,
}: TInvoiceSellItemFormProps) {
  const { t } = useTranslation();
  const [isMoreOptionsVisible, setIsMoreOptionsVisible] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    register,
    formState: { errors },
  } = useForm<TInvoiceSellItemFormData>({
    schema: FORM_VALIDATION_SCHEMA,
    defaultValues,
    context: { isQuantityFieldVisible, isDiscountFieldVisible },
  });
  const vatRate = watch('vat.rate');
  const discount = watch('discount');
  const unitType = watch('unitType');

  useEffect(() => {
    register('id', {
      value: defaultValues.id,
    });
    register('currency', {
      value: defaultValues.currency,
    });
  }, [defaultValues.currency, defaultValues.id]);

  useEffect(() => {
    if (isMoreOptionsVisible) {
      register('discount', {
        value: defaultValues.discount,
      });
      register('description', {
        value: defaultValues.description,
      });
      register('unitType', {
        value: defaultValues.unitType,
      });
    }
  }, [isMoreOptionsVisible]);

  useEffect(() => {
    if (onWatchValues && onWatchAmountDetailsValues) {
      const subscription = watch((value, { name }) => {
        if (
          [
            'vat.rate',
            'unitPrice',
            'quantity',
            'discount.type',
            'discount.percentage',
            'discount.amount',
          ].includes(name as string)
        ) {
          onWatchAmountDetailsValues(value as TInvoiceSellItemFormData);
        } else {
          onWatchValues(value as TInvoiceSellItemFormData);
        }
      });
      return () => subscription.unsubscribe();
    }
    return undefined;
  }, [watch]);

  function handleSetVatRate(vatRateValue?: VatRate) {
    setValue('vat.rate', vatRateValue);
    setValue('vat.title', !isNil(vatRateValue) ? t(getTitleIdByVatRate(vatRateValue)) : '');
  }

  function handleSetDiscountPercentage(percentage?: TDiscount['percentage']) {
    if (!percentage) {
      return setValue('discount', undefined);
    }
    return setValue('discount.percentage', percentage);
  }

  function handleSetDiscountType(discountType: TDiscount['type']) {
    if (discountType === InvoiceItemDiscountType.ABSOLUTE) {
      setValue('discount.amount', undefined);
      setValue('discount.percentage', undefined);
    }
    setValue('discount.type', discountType);
  }

  function renderButtonStickyContent() {
    if (onDeleteItem) {
      return (
        <Box mb="m">
          <Button labelId="button.deleteItem" variant="error" onClick={onDeleteItem} />
        </Box>
      );
    }
    return null;
  }

  return (
    <BaseForm
      onSubmit={handleSubmit(onSubmit)}
      submitButtonLabelId={submitButtonLabelId}
      buttonStickyContent={renderButtonStickyContent()}
      submitButtonProps={{ loading, ...submitButtonProps }}>
      <FieldAmountTextInput
        name="unitPrice"
        control={control}
        errors={errors}
        placeholderId="placeholder.zero"
        currency={defaultValues.currency}
        isBottomContentVisible={false}
        inputHeight={64}
        maxLength={13}
        autoFocus={focusFieldName === 'unitPrice'}
      />
      <Box display="flex" flexDirection="row" alignItems="center" gap="m">
        {isQuantityFieldVisible && (
          <Box flex={0.5}>
            <FieldNumberInput
              labelId="label.quantity"
              placeholderId="placeholder.empty"
              name="quantity"
              control={control}
              errors={errors}
            />
          </Box>
        )}
        <Box flex={1}>
          <FieldTextInput
            name="title"
            labelId="label.title"
            placeholderId="placeholder.empty"
            control={control}
            errors={errors}
            maxLength={128}
          />
        </Box>
      </Box>
      <VatRateFields
        control={control}
        errors={errors}
        vatRate={vatRate}
        trigger={trigger}
        onSetVatRate={handleSetVatRate}
      />
      {isMoreOptionsVisible && (
        <>
          {isDiscountFieldVisible && (
            <DiscountFields
              control={control}
              errors={errors}
              discount={discount}
              trigger={trigger}
              onSetDiscountPercentage={handleSetDiscountPercentage}
              onSetDiscountType={handleSetDiscountType}
              currency={defaultValues.currency}
              defaultValue={defaultValues.discount}
            />
          )}
          <FieldTextInput
            name="description"
            labelId="label.description"
            placeholderId="placeholder.empty"
            control={control}
            errors={errors}
            maxLength={256}
          />
          <UnitTypeSelectField
            control={control}
            errors={errors}
            onSelectUnitType={(unitTypeValue) => setValue('unitType', unitTypeValue)}
            unitType={unitType}
          />
        </>
      )}
      <Box display="flex" justifyContent="center" mb="l">
        <TextLink
          labelId={isMoreOptionsVisible ? 'label.lessOptions' : 'label.moreOptions'}
          leftIcon={isMoreOptionsVisible ? ChevronUpIcon : ChevronDownIcon}
          onPress={() => setIsMoreOptionsVisible(!isMoreOptionsVisible)}
          block
        />
      </Box>
    </BaseForm>
  );
}

export default InvoiceSellItemForm;
