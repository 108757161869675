import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import masks from '@wamoio/common/lib/masks';
import { getOr } from 'lodash/fp';

import { FieldTextInput } from 'components/v2/Fields';
import FormErrorLabel from 'components/v2/core/FormErrorLabel';
import Box from 'components/core/Box';

import { COUNTRY_DEFAULT_VALUE } from 'constants/form';
import { TCountry } from 'types/common';

import FieldCallingCode from './views/FieldCallingCode';
import { StyledPhoneNumberInputContainer } from './FieldPhoneNumber.styles';
import { TFieldPhoneNumberProps } from './FieldPhoneNumber.types';

function FieldPhoneNumber({
  name,
  control,
  errors,
  trigger,
  isFormSubmitted,
  placeholderId = 'placeholder.empty',
  disabled = false,
  defaultValue = COUNTRY_DEFAULT_VALUE,
  marginBottom = 'm',
  countrySelectProps,
}: TFieldPhoneNumberProps) {
  const errorMessageId = getOr(undefined, name, errors)?.message;

  const country: TCountry = useWatch({
    name: `${name}.country`,
    control,
    defaultValue,
  });

  useEffect(() => {
    if (isFormSubmitted) {
      trigger(name);
    }
  }, [country?.code, isFormSubmitted]);

  return (
    <Box marginBottom={marginBottom}>
      <StyledPhoneNumberInputContainer alignment="row.verticalCenter">
        <FieldCallingCode
          name={`${name}.country`}
          control={control}
          trigger={trigger}
          defaultValue={defaultValue}
          countrySelectProps={countrySelectProps}
          hasError={!!errorMessageId}
          disabled={disabled}
          isFormSubmitted={isFormSubmitted}
        />
        <Box flex={1} ml="s">
          <FieldTextInput
            name={`${name}.nationalNumber`}
            labelId="label.phoneNumber"
            placeholderId={placeholderId}
            control={control}
            errors={errors}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onFormatValue={(value) => masks.phoneNumber.mask(value, country.code as any)}
            autoCapitalize="none"
            autoComplete="tel-national"
            marginBottom="none"
          />
        </Box>
      </StyledPhoneNumberInputContainer>
      {isFormSubmitted && errorMessageId && <FormErrorLabel textId={errorMessageId} />}
    </Box>
  );
}

export default FieldPhoneNumber;
