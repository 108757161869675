import styled from 'styled-components';

import { getColor } from 'helpers/theme';
import {
  SWITCH_BUTTON_BORDER_RADIUS,
  SWITCH_BUTTON_CHECKED_TRACK_POSITION,
  SWITCH_BUTTON_HEIGHT,
  SWITCH_BUTTON_TRACK_BORDER_RADIUS,
  SWITCH_BUTTON_TRACK_HEIGHT,
  SWITCH_BUTTON_TRACK_PADDING,
  SWITCH_BUTTON_TRACK_WIDTH,
  SWITCH_BUTTON_WIDTH,
} from './SwitchButton.constants';

export const SwitchButtonContainer = styled.label<{ disabled?: boolean }>`
  overflow: hidden;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  border-radius: ${SWITCH_BUTTON_BORDER_RADIUS}px;
`;

export const SwitchButtonInput = styled.input`
  position: absolute;
  visibility: hidden;
`;

export const SwitchButtonShape = styled.div`
  background-color: ${getColor('backgroundSecondary')};
  width: ${SWITCH_BUTTON_WIDTH}px;
  height: ${SWITCH_BUTTON_HEIGHT}px;
  border-radius: ${SWITCH_BUTTON_BORDER_RADIUS}px;
  position: relative;
  transition: all 0.2s;

  &:before {
    content: '';
    display: block;
    position: absolute;
    border-radius: ${SWITCH_BUTTON_TRACK_BORDER_RADIUS}px;
    width: ${SWITCH_BUTTON_TRACK_WIDTH}px;
    height: ${SWITCH_BUTTON_TRACK_HEIGHT}px;
    top: ${SWITCH_BUTTON_TRACK_PADDING}px;
    left: ${SWITCH_BUTTON_TRACK_PADDING}px;
    transition: all 0.2s;
    background-color: ${getColor('backgroundPrimary')};
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  }

  input:checked + & {
    background-color: ${getColor('buttonPrimary')};
    &:before {
      left: ${SWITCH_BUTTON_CHECKED_TRACK_POSITION}px;
    }
  }
`;
