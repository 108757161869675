import styled from 'styled-components';

import Pressable from 'components/v2/core/Pressable/Pressable';

const StyledPressable = styled(Pressable)`
  display: flex;
  flex: 1;
  z-index: 1;
  justify-content: center;
  align-items: center;
  padding: 6px 4px;
  overflow: hidden;
`;

export default StyledPressable;
