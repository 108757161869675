import React from 'react';
import { Controller, UseControllerReturn } from 'react-hook-form';

import SegmentedControl from 'components/v2/core/SegmentedControl';

import { TFieldSegmentedControlProps } from './FieldSegmentedControl.types';

function FieldSegmentedControl<T>({
  name,
  control,
  options,
  defaultValue = options[0].value,
}: TFieldSegmentedControlProps<T>) {
  function handleRender({ field }: UseControllerReturn) {
    const { onChange, value } = field;

    return <SegmentedControl<T> options={options} defaultValue={value} onChange={onChange} />;
  }

  return (
    <Controller name={name} control={control} render={handleRender} defaultValue={defaultValue} />
  );
}

export default FieldSegmentedControl;
