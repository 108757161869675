import React, { ReactElement, memo } from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import { AlertOctagonIcon } from 'components/v2/Icons';

import { TFormErrorLabelProps } from './FormErrorLabel.types';

function FormErrorLabel({ textId, ...rest }: TFormErrorLabelProps): ReactElement {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" mt="s" pl="s" {...rest}>
      <Box alignSelf="flex-start">
        <AlertOctagonIcon width={16} height={16} iconColor="statusErrorPrimary" />
      </Box>
      <Box flex={1}>
        <Text textId={textId} color="statusErrorPrimary" variant="textBodySmallBold" ml="xs" />
      </Box>
    </Box>
  );
}

export default memo(FormErrorLabel);
