import React from 'react';
import { Controller, UseControllerReturn } from 'react-hook-form';
import { getOr } from 'lodash/fp';

import Box from 'components/core/Box';
import FormErrorLabel from 'components/v2/core/FormErrorLabel';
import SelectButton from 'components/v2/core/SelectButton';

import { TI18nId } from 'types/common';

import { TFieldSelectProps } from './FieldSelect.types';

function FieldSelect<T>({
  name,
  labelId,
  control,
  errors,
  marginBottom = 'm',
  defaultValue,
  disabled,
  renderIcon,
  onPress,
  defaultIcon: DefaultIcon,
  renderValue,
  isOnlyIconVisible,
}: TFieldSelectProps<T>) {
  const errorMessageId = getOr(undefined, name, errors)?.message;

  function renderIconComponent(value: T) {
    if (value) {
      return renderIcon(value);
    }
    return <DefaultIcon iconColor={errorMessageId ? 'statusErrorPrimary' : 'textSecondary'} />;
  }

  function handleRender({ field }: UseControllerReturn) {
    const { onChange, value } = field;

    function handleOnPress() {
      onPress({ onChange });
    }

    return (
      <SelectButton
        labelId={labelId}
        value={renderValue(value)}
        hasError={!!errorMessageId}
        icon={renderIconComponent(value)}
        onClick={handleOnPress}
        disabled={disabled}
        isOnlyIconVisible={isOnlyIconVisible}
      />
    );
  }

  return (
    <Box marginBottom={marginBottom}>
      <Controller name={name} control={control} defaultValue={defaultValue} render={handleRender} />
      {errorMessageId && <FormErrorLabel textId={errorMessageId as TI18nId} />}
    </Box>
  );
}

export default FieldSelect;
