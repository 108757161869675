import React, { ReactElement, forwardRef } from 'react';

import { SwitchButtonContainer, SwitchButtonInput, SwitchButtonShape } from './SwitchButton.styles';
import { TSwitchButtonProps } from './SwitchButton.types';

function SwitchButton(
  { name, disabled, value, ...rest }: TSwitchButtonProps,
  ref: React.Ref<HTMLInputElement>
): ReactElement {
  return (
    <SwitchButtonContainer disabled={disabled} htmlFor={name}>
      <SwitchButtonInput
        ref={ref}
        type="checkbox"
        id={name}
        value={value?.toString()}
        defaultChecked={value}
        {...rest}
      />
      <SwitchButtonShape />
    </SwitchButtonContainer>
  );
}

export default forwardRef(SwitchButton);
