import { RADIO_STATE_STYLE_MAP } from './Radio.constants';
import {
  TRadioGetIndicatorColorProps,
  TRadioGetIndicatorColorReturn,
  TRadioGetStyleProps,
  TRadioGetStyleReturn,
} from './Radio.types';

export function getRadioStyle({
  checked = false,
  hovered = false,
  disabled = false,
  pressed = false,
}: TRadioGetStyleProps): TRadioGetStyleReturn {
  const radioStatus = RADIO_STATE_STYLE_MAP[checked ? 'checked' : 'unchecked'];

  if (disabled) {
    return radioStatus.disabled;
  }

  if (pressed) {
    return radioStatus.onTap;
  }

  if (hovered) {
    return radioStatus.hover;
  }

  return radioStatus.default;
}

export function getIndicatorColor({
  checked = false,
  hovered = false,
}: TRadioGetIndicatorColorProps): TRadioGetIndicatorColorReturn {
  const radioStatus = RADIO_STATE_STYLE_MAP[checked ? 'checked' : 'unchecked'];

  if (hovered) {
    return radioStatus.hover.indicator;
  }

  return radioStatus.default.indicator;
}
