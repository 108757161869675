import React from 'react';

import Text from 'components/v2/core/Text';

import { TAmountInputConversionRateProps } from '../AmountInput.types';

function AmountInputConversionRate({ conversionInfo }: TAmountInputConversionRateProps) {
  return (
    <Text
      textId="label.amountInputConversionRate"
      variant="textBodySmall"
      color="textSecondary"
      mt="s"
      values={{
        targetAmount: conversionInfo.targetAmount,
        targetCurrencyCode: conversionInfo.targetCurrencyCode,
        baseAmount: conversionInfo.baseAmount,
        baseCurrencyCode: conversionInfo.baseCurrencyCode,
      }}
    />
  );
}

export default AmountInputConversionRate;
