import React, { useEffect, useState } from 'react';
import { DayPicker, DayModifiers } from 'react-day-picker';

import Modal from 'components/core/Modal';
import Box from 'components/core/Box';
import Text from 'components/core/Text';
import Button from 'components/core/Button';
import TextButton from 'components/core/TextButton';
import Card from 'components/core/Card';

import date from 'helpers/date';

import { TSelectDateModalProps } from '../SelectDate.types';

function SelectDateModal({
  modalRef,
  value,
  onApplySelectDate,
  disabledDays,
  headerTitleId,
  ...rest
}: TSelectDateModalProps) {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(value);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  function handleDaySelect(day: Date, { disabled }: DayModifiers) {
    if (disabled) {
      return;
    }
    setSelectedDate(day);
  }

  function handleApplySelectDate() {
    if (selectedDate) {
      onApplySelectDate(selectedDate);
    }
    modalRef.current?.show(false);
  }

  return (
    <Modal
      ref={modalRef}
      header={{ id: headerTitleId ?? 'label.selectDate' }}
      afterClose={() => setSelectedDate(value)}
      secondary>
      <Card>
        <Box p="m" alignment="row.horizontalCenter" justifyContent="space-between">
          <Box flex={1}>
            {selectedDate && (
              <Text variant="mediumTextSemiBold" color="textSecondary">
                {date.format({ date: selectedDate, formatType: 'dd MMM yyyy' })}
              </Text>
            )}
          </Box>
          <TextButton
            id="button.reset"
            variant="secondary"
            textVariant="mediumTextBold"
            onClick={() => setSelectedDate(undefined)}
          />
        </Box>
        <Box paddingX="m">
          <DayPicker
            numberOfMonths={1}
            weekStartsOn={1}
            selected={selectedDate}
            onDayClick={handleDaySelect}
            fromMonth={new Date()}
            disabled={disabledDays}
            fixedWeeks
            {...rest}
          />
        </Box>
      </Card>
      <Button id="button.apply" mt="l" onClick={handleApplySelectDate} />
    </Modal>
  );
}

export default SelectDateModal;
