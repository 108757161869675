import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import { InfoCircleIcon } from 'components/v2/Icons';

import { TInvoicePrefixViewProps } from '../InvoiceSettingsDefaultForm.types';

function InvoicePrefixView({ invoiceNumber, invoiceNumberPrefix }: TInvoicePrefixViewProps) {
  if (!invoiceNumber || !invoiceNumberPrefix) {
    return <></>;
  }

  return (
    <Box style={{ marginTop: '-0.5rem' }} alignment="row.verticalCenter" gap="xxs" mb="m">
      <Box display="flex" alignSelf="flex-start">
        <InfoCircleIcon iconColor="statusNaturalPrimary" />
      </Box>
      <Box flex={1}>
        <Text
          textId="messages.nextInvoicePrefix.information"
          variant="textBodySubBold"
          color="statusNaturalPrimary"
          values={{
            invoiceNumber,
            invoiceNumberPrefix,
          }}
        />
      </Box>
    </Box>
  );
}

export default InvoicePrefixView;
