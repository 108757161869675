import React from 'react';

import { FieldTextInput } from 'components/v2/Fields';

import {
  getNameInputLabelIdByInvoiceCustomerType,
  getVatInputLabelIdByInvoiceCustomerType,
} from 'enums/InvoiceCustomerType';

import { TInvoiceCustomerInformationFieldsProps } from '../InvoiceCustomerForm.types';

function InvoiceCustomerInformationFields({
  control,
  errors,
  invoiceCustomerType,
}: TInvoiceCustomerInformationFieldsProps) {
  return (
    <>
      <FieldTextInput
        name="customerName"
        control={control}
        errors={errors}
        labelId={getNameInputLabelIdByInvoiceCustomerType(invoiceCustomerType)}
        placeholderId="placeholder.empty"
        autoCapitalize="words"
        maxLength={250}
      />
      <FieldTextInput
        name="email"
        labelId="label.customerEmail"
        placeholderId="placeholder.empty"
        control={control}
        errors={errors}
        autoCapitalize="none"
        inputMode="email"
      />
      <FieldTextInput
        name="contactName"
        control={control}
        errors={errors}
        labelId="label.contactNameOptional"
        placeholderId="placeholder.empty"
        autoCapitalize="words"
        maxLength={250}
      />
      <FieldTextInput
        name="tin"
        control={control}
        errors={errors}
        labelId={getVatInputLabelIdByInvoiceCustomerType(invoiceCustomerType)}
        placeholderId="placeholder.empty"
        autoCapitalize="characters"
        marginBottom="xxl"
      />
    </>
  );
}

export default InvoiceCustomerInformationFields;
