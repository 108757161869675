import { TRadioStateStyleMap } from './Radio.types';

export const RADIO_SIZE = 24;
export const RADIO_BORDER_RADIUS = RADIO_SIZE / 2;
export const RADIO_INDICATOR_SIZE = 8;
export const RADIO_INDICATOR_BORDER_RADIUS = RADIO_INDICATOR_SIZE / 2;
export const RADIO_BORDER_WIDTH = 1.5;

export const RADIO_STATE_STYLE_MAP: TRadioStateStyleMap = {
  checked: {
    default: { background: 'textActive', border: 'borderOutlineActive', indicator: 'textInverted' },
    disabled: { background: 'textPassive', border: 'textPassive', indicator: 'textInverted' },
    hover: { background: 'textActive', border: 'borderOutlineActive', indicator: 'textInverted' },
    onTap: { background: 'textActive', border: 'borderOutlineActive', indicator: 'textInverted' },
  },
  unchecked: {
    default: { background: 'transparent', border: 'borderOutlineActive', indicator: 'transparent' },
    disabled: { background: 'transparent', border: 'textPassive', indicator: 'transparent' },
    hover: {
      background: 'textInverted',
      border: 'borderOutlineActive',
      indicator: 'buttonGhostPrimary',
    },
    onTap: {
      background: 'textInverted',
      border: 'borderOutlineActive',
      indicator: 'buttonGhostPrimaryHover',
    },
  },
};
