import React from 'react';

import { TRadioProps } from './Radio.types';
import { RadioBackground, RadioIndicator, RadioContainer } from './Radio.styles';
import { getRadioStyle } from './Radio.helpers';
import {
  RADIO_BORDER_RADIUS,
  RADIO_BORDER_WIDTH,
  RADIO_INDICATOR_BORDER_RADIUS,
  RADIO_INDICATOR_SIZE,
  RADIO_SIZE,
} from './Radio.constants';

function Radio({ id, checked = false, disabled = false, pressed, ...rest }: TRadioProps) {
  const radioStyle = getRadioStyle({ checked, pressed, disabled });
  return (
    <RadioContainer
      display="flex"
      position="relative"
      alignItems="center"
      justifyContent="center"
      width={RADIO_SIZE}
      height={RADIO_SIZE}
      borderRadius={RADIO_BORDER_RADIUS}
      borderWidth={RADIO_BORDER_WIDTH}
      borderStyle="solid"
      disabled={disabled}
      borderColor={radioStyle.border}>
      <RadioBackground
        className="radio-background"
        width={checked ? RADIO_SIZE : 0}
        height={checked ? RADIO_SIZE : 0}
        borderRadius={RADIO_BORDER_RADIUS}
        backgroundColor={radioStyle.background}
      />
      <RadioIndicator
        className="radio-indicator"
        position="absolute"
        zIndex={1}
        width={RADIO_INDICATOR_SIZE}
        height={RADIO_INDICATOR_SIZE}
        borderRadius={RADIO_INDICATOR_BORDER_RADIUS}
        backgroundColor={radioStyle.indicator}
      />
      <input type="radio" id={id} {...rest} />
    </RadioContainer>
  );
}

export default Radio;
