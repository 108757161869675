export const SELECT_BUTTON_ERROR_BORDER_WIDTH = 1;
export const SELECT_BUTTON_ARROW_ICON_SIZE = 24;
export const SELECT_BUTTON_HEIGHT = 64;
export const SELECT_BUTTON_BORDER_RADIUS = 8;
export const SELECT_BUTTON_ICON_DISABLED_OPACITY = 0.3;
export const SELECT_BUTTON_ICON_SIZE = 32;
export const SELECT_BUTTON_ICON_PROPS = {
  width: SELECT_BUTTON_ICON_SIZE,
  height: SELECT_BUTTON_ICON_SIZE,
};
export const SELECT_BUTTON_IMAGE_PROPS = {
  style: {
    width: SELECT_BUTTON_ICON_SIZE,
    height: SELECT_BUTTON_ICON_SIZE,
  },
};
