import React, { forwardRef, Ref } from 'react';

import Text from 'components/v2/core/Text';
import Box from 'components/core/Box';

import { TEXT_AREA_INPUT_HEIGHT } from './TextAreaInput.constants';
import { TTextAreaInputProps } from './TextAreaInput.types';
import { StyledTextInputBase } from './TextAreaInput.styles';

function TextAreaInput(
  { value, maxLength, inputHeight = TEXT_AREA_INPUT_HEIGHT, ...rest }: TTextAreaInputProps,
  ref: Ref<HTMLTextAreaElement>
) {
  return (
    <Box>
      <StyledTextInputBase
        ref={ref}
        value={value}
        {...rest}
        maxLength={maxLength}
        variant="textarea"
        height={inputHeight}
      />
      <Text mt="s" variant="textBodySub" color="textSecondary" textAlign="right">{`${
        String(value ?? '')?.length ?? 0
      }/${maxLength}`}</Text>
    </Box>
  );
}

export default forwardRef(TextAreaInput);
