import React from 'react';
import { isNil } from 'lodash/fp';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import { ChevronDownIcon } from 'components/v2/Icons';
import Pressable from 'components/v2/core/Pressable';

import { TSelectButtonProps } from './SelectButton.types';
import { getLabelTextColor } from './SelectButton.helpers';
import {
  SELECT_BUTTON_ARROW_ICON_SIZE,
  SELECT_BUTTON_ICON_DISABLED_OPACITY,
  SELECT_BUTTON_ICON_PROPS,
  SELECT_BUTTON_IMAGE_PROPS,
} from './SelectButton.constants';

function SelectButton({
  labelId,
  value,
  icon,
  disabled,
  hasError,
  isOnlyIconVisible = false,
  ...rest
}: TSelectButtonProps) {
  const iconColorKey = hasError ? 'statusErrorPrimary' : 'textSecondary';
  const hasValue = !isNil(value);

  return (
    <Pressable {...rest} disabled={disabled}>
      {({ pressed }) => (
        <Box
          display="flex"
          py="sm"
          px="m"
          borderRadius={8}
          backgroundColor={pressed ? 'buttonGhostPrimary' : 'backgroundSecondary'}>
          {!isOnlyIconVisible && (
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              mr="m"
              height={40}
              gap="xxs"
              justifyContent="center">
              <Text
                textId={labelId}
                variant={hasValue ? 'textBodySmall' : 'textBodySub'}
                color={getLabelTextColor({ hasValue, disabled, hasError })}
              />
              {hasValue && (
                <Text
                  variant="textBodySubBold"
                  color={disabled ? 'textPassive' : 'textPrimary'}
                  numberOfLines={1}>
                  {value}
                </Text>
              )}
            </Box>
          )}
          <Box display="flex" flexDirection="row" alignItems="center">
            {icon && (
              <Box mr="s" {...(disabled && { opacity: SELECT_BUTTON_ICON_DISABLED_OPACITY })}>
                {React.cloneElement(icon, {
                  iconColor: iconColorKey,
                  ...SELECT_BUTTON_ICON_PROPS,
                  ...SELECT_BUTTON_IMAGE_PROPS,
                })}
              </Box>
            )}
            <Box {...(disabled && { opacity: SELECT_BUTTON_ICON_DISABLED_OPACITY })}>
              <ChevronDownIcon
                width={SELECT_BUTTON_ARROW_ICON_SIZE}
                height={SELECT_BUTTON_ARROW_ICON_SIZE}
                iconColor={iconColorKey}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Pressable>
  );
}

export default SelectButton;
