import React from 'react';

import { ArrowLeftIcon } from 'components/v2/Icons';

import NavigationHeaderBaseButton from './views/NavigationHeaderBaseButton';
import { TNavigationHeaderBackButtonProps } from './Navigation.types';

function NavigationHeaderBackButton({ onPress }: TNavigationHeaderBackButtonProps) {
  return (
    <NavigationHeaderBaseButton onPress={onPress}>
      <ArrowLeftIcon />
    </NavigationHeaderBaseButton>
  );
}

export default NavigationHeaderBackButton;
