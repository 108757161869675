import React from 'react';
import { useTranslation } from 'react-i18next';

import { FieldSelect } from 'components/v2/Fields';
import InvoiceSellItemUnitTypeSelectModal from 'modals/InvoiceSellItemUnitTypeSelectModal';

import InvoiceItemUnitType, {
  getIconByInvoiceItemUnitType,
  getLabelIdByInvoiceItemUnitType,
} from 'enums/InvoiceItemUnitType';
import { useModal } from 'components/core/Modal';

import { TUnitTypeSelectFieldProps } from '../InvoiceSellItemForm.types';

function UnitTypeSelectField({
  unitType,
  errors,
  control,
  onSelectUnitType,
}: TUnitTypeSelectFieldProps) {
  const { t } = useTranslation();
  const invoiceSellItemUnitTypeSelectModalRef = useModal();

  function renderUnitTypeIcon(value: InvoiceItemUnitType) {
    const Icon = getIconByInvoiceItemUnitType(value);
    return <Icon />;
  }

  return (
    <>
      <FieldSelect<InvoiceItemUnitType>
        name="unitType"
        labelId="label.itemUnit"
        control={control}
        errors={errors}
        defaultIcon={getIconByInvoiceItemUnitType(unitType)}
        renderIcon={renderUnitTypeIcon}
        renderValue={(value) => t(getLabelIdByInvoiceItemUnitType(value))}
        onPress={() => invoiceSellItemUnitTypeSelectModalRef.current?.show(true)}
        marginBottom="m"
      />
      <InvoiceSellItemUnitTypeSelectModal
        modalRef={invoiceSellItemUnitTypeSelectModalRef}
        unitType={unitType}
        onSelectUnitType={onSelectUnitType}
      />
    </>
  );
}

export default UnitTypeSelectField;
