import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { BaseForm, useForm } from 'components/v2/Forms';
import { FieldSwitchButtonItem, FieldAmountTextInput } from 'components/v2/Fields';
import CardItem from 'components/v2/CardItem';
import Card from 'components/core/Card';
import Box from 'components/core/Box';
import SelectButton from 'components/v2/core/SelectButton';
import PageHeader from 'components/v2/PageHeader';
import InfoItem from 'components/v2/core/InfoItem';

import CardSpendingLimitTypeSelectModal from 'modals/CardSpendingLimitTypeSelectModal';
import CardSpendingLimitType, {
  getLabelIdByCardSpendingLimitType,
  getResetOnLabelIdByCardSpendingLimitType,
} from 'enums/CardSpendingLimitType';
import { TCardSpendingLimitTypeSelectModalRef } from 'modals/CardSpendingLimitTypeSelectModal/CardSpendingLimitTypeSelectModal.types';

import { TCardSpendLimitFormProps } from '../CardDetailSpendLimitModal.types';
import {
  INPUT_ANIMATION_VARIANTS,
  CARD_SPEND_LIMIT_FORM_VALIDATION_SCHEMA,
  MAX_AMOUNT_INPUT_VALUE,
} from '../CardDetailSpendLimitModal.constants';

const AnimatedBox = motion.div;

function CardSpendLimitModalForm({
  card,
  defaultValues,
  loading,
  onSubmit,
}: TCardSpendLimitFormProps) {
  const { t } = useTranslation();
  const cardSpendingLimitTypeSelectModalRef = useRef<TCardSpendingLimitTypeSelectModalRef>(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm({
    schema: CARD_SPEND_LIMIT_FORM_VALIDATION_SCHEMA,
    defaultValues,
  });
  const cardSpendingLimitType = watch('cardSpendingLimitType');
  const isSpendLimitActive = watch('isSpendLimitActive');
  const isSingleTransactionLimitActive = watch('isSingleTransactionLimitActive');

  function handleChangeCardSpendingLimitType(type: CardSpendingLimitType) {
    setValue('cardSpendingLimitType', type, { shouldDirty: true });
  }

  return (
    <BaseForm
      onSubmit={handleSubmit(onSubmit)}
      submitButtonLabelId="button.saveLimits"
      submitButtonProps={{
        disabled: !isDirty,
        loading,
      }}>
      <PageHeader
        titleId="label.spendingLimits"
        descriptionId="screens.cardSpendLimit.description"
      />
      <Box display="grid" gap="m" mb="m">
        <Card p="s">
          <CardItem card={card} />
        </Card>
        <Card p="s">
          <FieldSwitchButtonItem
            name="isSpendLimitActive"
            labelId="label.spendingLimit"
            control={control}
            errors={errors}
          />
          {isSpendLimitActive && (
            <Box p="s">
              <AnimatedBox variants={INPUT_ANIMATION_VARIANTS} initial="default" animate="enter">
                <FieldAmountTextInput
                  name="spendLimitAmount"
                  control={control}
                  errors={errors}
                  placeholderId="placeholder.zero"
                  currency={card.ledger.currency}
                  isAllowed={(values) => (values.floatValue ?? 0) < MAX_AMOUNT_INPUT_VALUE}
                  hasCurrencyIcon={false}
                  isBottomContentVisible={false}
                  maxLength={9}
                />
                <SelectButton
                  labelId="label.frequency"
                  {...(cardSpendingLimitType && {
                    value: t(getLabelIdByCardSpendingLimitType(cardSpendingLimitType)),
                  })}
                  onClick={() =>
                    cardSpendingLimitTypeSelectModalRef.current?.show({
                      cardSpendingLimitType,
                    })
                  }
                />
                {cardSpendingLimitType && (
                  <Box mt="s">
                    <InfoItem
                      labelId="label.resets"
                      value={t(getResetOnLabelIdByCardSpendingLimitType(cardSpendingLimitType))}
                      valueProps={{
                        numberOfLines: 1,
                      }}
                    />
                  </Box>
                )}
              </AnimatedBox>
            </Box>
          )}
        </Card>
        <Card p="s">
          <FieldSwitchButtonItem
            name="isSingleTransactionLimitActive"
            labelId="label.singleTransactionLimit"
            control={control}
            errors={errors}
          />
          {isSingleTransactionLimitActive && (
            <Box p="s">
              <AnimatedBox variants={INPUT_ANIMATION_VARIANTS} initial="default" animate="enter">
                <FieldAmountTextInput
                  name="singleTransactionAmount"
                  control={control}
                  errors={errors}
                  placeholderId="placeholder.zero"
                  currency={card.ledger.currency}
                  isAllowed={(values) => (values.floatValue ?? 0) < MAX_AMOUNT_INPUT_VALUE}
                  hasCurrencyIcon={false}
                  isBottomContentVisible={false}
                  maxLength={9}
                  marginBottom="none"
                />
              </AnimatedBox>
            </Box>
          )}
        </Card>
      </Box>
      <CardSpendingLimitTypeSelectModal
        modalRef={cardSpendingLimitTypeSelectModalRef}
        onSelectCardSpendingLimitType={handleChangeCardSpendingLimitType}
      />
    </BaseForm>
  );
}

export default CardSpendLimitModalForm;
