import React from 'react';
import { isNil } from 'lodash/fp';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import Dot from 'components/v2/core/Dot';
import IconButton from 'components/v2/core/IconButton';
import Pressable from 'components/v2/core/Pressable';
import { ArrowRightIcon } from 'components/v2/Icons';

import { TSectionHeaderTitleProps } from './SectionHeaderTitle.types';

function SectionHeaderTitle({
  titleId,
  titleProps,
  titleExtra,
  p,
  mb,
  onPress,
}: TSectionHeaderTitleProps) {
  return (
    <Box pointerEvents={onPress ? 'auto' : 'none'}>
      <Pressable onClick={onPress}>
        <Box display="flex" gap="xs" flexDirection="row" alignItems="center" p={p} mb={mb}>
          <Text textId={titleId} variant="textBodySubBold" {...titleProps} />
          {!isNil(titleExtra) && (
            <>
              <Dot size={4} color="textPassive" />
              <Text variant="textBodySubBold" color="textSecondary">
                {titleExtra}
              </Text>
            </>
          )}
          {onPress && (
            <Box pointerEvents="none">
              <IconButton icon={ArrowRightIcon} variant="ghost" size="small" />
            </Box>
          )}
        </Box>
      </Pressable>
    </Box>
  );
}

export default SectionHeaderTitle;
