import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import { XCloseIcon } from 'components/v2/Icons';
import Pressable from 'components/v2/core/Pressable/Pressable';

import { getTagStyle } from './Tag.helpers';
import { TTagProps } from './Tag.types';
import {
  TAG_BORDER_RADIUS,
  TAG_BORDER_WIDTH,
  TAG_ICON_SIZE,
  TAG_MIN_HEIGHT,
} from './Tag.constants';

function Tag({ labelId, labelValues, closable, onClick, selected = false }: TTagProps) {
  return (
    <Pressable onClick={onClick}>
      {({ pressed }) => {
        const tagStyle = getTagStyle({ pressed, selected });
        return (
          <Box
            p="s"
            borderStyle="solid"
            minHeight={TAG_MIN_HEIGHT}
            borderRadius={TAG_BORDER_RADIUS}
            borderWidth={TAG_BORDER_WIDTH}
            display="flex"
            alignItems="center"
            backgroundColor={tagStyle.background}
            borderColor={tagStyle.border}>
            <Text
              textId={labelId}
              values={labelValues}
              color={tagStyle.text}
              variant="textBodyBold"
            />
            {closable && (
              <>
                <Box
                  display="flex"
                  mx="s"
                  width={TAG_BORDER_WIDTH}
                  height={TAG_ICON_SIZE}
                  backgroundColor={tagStyle.border}
                />
                <XCloseIcon
                  width={TAG_ICON_SIZE}
                  height={TAG_ICON_SIZE}
                  iconColor={tagStyle.text}
                />
              </>
            )}
          </Box>
        );
      }}
    </Pressable>
  );
}

export default Tag;
