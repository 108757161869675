import React, { useRef } from 'react';
import { Controller, UseControllerReturn } from 'react-hook-form';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import { Globe04Icon } from 'components/v2/Icons';
import CountryImage from 'components/CountryImage';
import Pressable from 'components/v2/core/Pressable';
import SelectCountryModal from 'modals/SelectCountryModal';

import { TCountry } from 'types/common';
import { TCountryDetailResponse } from 'context/CountriesProvider/CountriesProvider.types';
import { useCountriesContext } from 'context/CountriesProvider';
import { TSelectCountryModalRef } from 'modals/SelectCountryModal/SelectCountryModal.types';

import { TFieldCallingCodeProps } from '../FieldPhoneNumber.types';
import { getCallingCodeStateStyle } from '../FieldPhoneNumber.helpers';

function FieldCallingCode({
  name,
  control,
  trigger,
  disabled,
  hasError,
  defaultValue,
  isFormSubmitted,
  countrySelectProps,
}: TFieldCallingCodeProps) {
  const modalRef = useRef<TSelectCountryModalRef>(null);
  const { countriesState } = useCountriesContext();

  function renderCountryIcon(countryCode: TCountry['code']) {
    if (countryCode) {
      return <CountryImage code={countryCode} size={32} />;
    }
    return <Globe04Icon width={32} height={32} />;
  }

  function handleRender({ field }: UseControllerReturn) {
    const { onChange, value } = field;

    function handleOnPress() {
      modalRef.current?.show({
        onSelect: (country: TCountryDetailResponse) => {
          onChange(country);
          if (isFormSubmitted) {
            trigger();
          }
        },
        headerTitleId: countrySelectProps?.headerTitleId,
      });
    }

    return (
      <Pressable onClick={handleOnPress} disabled={disabled}>
        {({ pressed, hovered }) => {
          const callingCodeStyle = getCallingCodeStateStyle({
            hasError,
            pressed,
            hovered,
          });
          return (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              px="m"
              borderRadius={8}
              overflow="hidden"
              height={64}
              borderWidth="1px"
              borderColor={callingCodeStyle.borderColor}
              backgroundColor={callingCodeStyle.backgroundColor}>
              <Box mr="s" width={56} pt="xxs">
                <Text
                  textId={value.name ?? 'label.country'}
                  variant="textBodySmall"
                  color={disabled ? 'textPassive' : 'textSecondary'}
                  numberOfLines={1}
                />
                <Text
                  variant="textBodySubBold"
                  color={disabled ? 'textPassive' : 'textPrimary'}
                  pb="xxs">
                  {`+${value.callingCode}`}
                </Text>
              </Box>
              <Box opacity={disabled ? 0.48 : 1} width={32} height={32}>
                {renderCountryIcon(value?.code)}
              </Box>
            </Box>
          );
        }}
      </Pressable>
    );
  }

  return (
    <>
      <Controller name={name} control={control} defaultValue={defaultValue} render={handleRender} />
      <SelectCountryModal modalRef={modalRef} options={countriesState.countries} showCallingCode />
    </>
  );
}

export default FieldCallingCode;
