import React, { ReactElement } from 'react';

import Box from 'components/core/Box';
import Button from 'components/v2/core/Button';

import { TI18nId } from 'types/common';

import { TBaseFormProps } from './BaseForm.types';
import { StyledForm } from './BaseForm.styles';

function BaseForm({
  children,
  onSubmit,
  submitButtonLabelId,
  submitButtonProps,
  isSubmitButtonVisible = true,
  buttonStickyContent,
}: TBaseFormProps): ReactElement {
  return (
    <StyledForm onSubmit={onSubmit} noValidate>
      <Box>{children}</Box>
      {isSubmitButtonVisible && (
        <Box>
          {buttonStickyContent}
          <Button
            labelId={submitButtonLabelId as TI18nId}
            {...submitButtonProps}
            onClick={onSubmit}
            type="submit"
          />
        </Box>
      )}
    </StyledForm>
  );
}

export default BaseForm;
