import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';
import SwitchButton from 'components/v2/core/SwitchButton';

import { TSwitchButtonItemProps } from './SwitchButtonItem.types';

function SwitchButtonItem({
  name,
  textId,
  value,
  textProps,
  onChange,
  ...rest
}: TSwitchButtonItemProps) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" p="s" {...rest}>
      <Box flex={1}>
        <Text textId={textId} variant="textBodySub" mr="s" {...textProps} />
      </Box>
      <SwitchButton name={name} value={value} onChange={onChange} />
    </Box>
  );
}

export default SwitchButtonItem;
