import React from 'react';

import ListItem from 'components/v2/core/ListItem';
import { CalendarIcon } from 'components/v2/Icons';

import { useModal } from 'components/core/Modal';
import { TI18nId } from 'types/common';
import date from 'helpers/date';
import SelectDateModal from 'components/core/SelectDate/views/SelectDateModal';

import { TSelectDateProps } from './SelectDate.types';

function SelectDate({
  labelId,
  value,
  onApplySelectDate,
  disabledDays,
  icon = CalendarIcon,
  ...rest
}: TSelectDateProps) {
  const modalRef = useModal();

  return (
    <>
      <ListItem
        labelId={labelId}
        descriptionId={
          value
            ? (date.format({ date: value, formatType: 'dd.MM.yyyy' }) as TI18nId)
            : 'label.selectDate'
        }
        descriptionProps={{
          color: 'statusNaturalPrimary',
        }}
        onPress={() => modalRef.current?.show(true)}
        icon={icon}
      />
      <SelectDateModal
        modalRef={modalRef}
        value={value}
        onApplySelectDate={onApplySelectDate}
        disabledDays={disabledDays}
        headerTitleId={labelId}
        {...rest}
      />
    </>
  );
}

export default SelectDate;
