import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { clamp } from 'lodash';

import Box from 'components/core/Box';

import borderRadius from 'theme/borderRadius';

import { TProgressBarProps } from './ProgressBar.types';
import { getAnimationVariants } from './ProgressBar.helpers';

function ProgressBar({ color, percentage, ...restProps }: TProgressBarProps) {
  const clampedPercentage = useMemo(() => clamp(percentage, 0, 100), [percentage]);
  return (
    <Box
      borderRadius={32}
      height={8}
      width="100%"
      backgroundColor="progressBarBackground"
      {...restProps}>
      <motion.div
        variants={getAnimationVariants(clampedPercentage)}
        initial="initial"
        animate="enter"
        transition={{ bounce: 0 }}>
        <Box height={8} backgroundColor={color} borderRadius={borderRadius.m} />
      </motion.div>
    </Box>
  );
}

export default ProgressBar;
