import React from 'react';
import { motion } from 'framer-motion';

import Box from 'components/core/Box';
import ListItemBase from 'components/v2/core/ListItemBase';
import Radio from 'components/v2/core/Radio';

import { TListItemBaseStates } from 'components/v2/core/ListItemBase/ListItemBase.types';

import { TRadioItemProps } from './RadioItem.types';
import {
  RADIO_ITEM_ICON_CONTAINER_SIZE,
  RADIO_INPUT_ANIMATION_VARIANTS,
} from './RadioItem.constants';

const AnimatedBox = motion(Box);

function RadioItem({
  id,
  labelId,
  descriptionId,
  icon: Icon,
  checked,
  onPress,
  disabled,
  marginTop,
  marginBottom,
  renderChildren,
}: TRadioItemProps) {
  function renderLeftComponent({ pressed }: TListItemBaseStates) {
    return (
      <Box
        display="flex"
        alignItems={descriptionId ? 'flex-start' : 'center'}
        height={RADIO_ITEM_ICON_CONTAINER_SIZE}
        pt={descriptionId ? 'xxs' : 'none'}>
        <Radio id={id} checked={checked} disabled={disabled} pressed={pressed} />
      </Box>
    );
  }

  function renderRightComponent({ pressed }: TListItemBaseStates) {
    if (Icon) {
      return (
        <Box
          display="flex"
          width={RADIO_ITEM_ICON_CONTAINER_SIZE}
          height={RADIO_ITEM_ICON_CONTAINER_SIZE}
          borderRadius={RADIO_ITEM_ICON_CONTAINER_SIZE}
          justifyContent="center"
          alignItems="center"
          backgroundColor={pressed || checked ? 'buttonGhostPrimaryHover' : 'backgroundPrimary'}>
          <Icon width={24} height={24} {...(disabled && { iconColor: 'textPassive' })} />
        </Box>
      );
    }
    return undefined;
  }

  return (
    <label htmlFor={id}>
      <ListItemBase
        labelId={labelId}
        descriptionId={descriptionId}
        onPress={onPress}
        left={renderLeftComponent}
        right={renderRightComponent}
        disabled={disabled}
        marginTop={marginTop}
        marginBottom={marginBottom}
      />
      {checked && renderChildren && (
        <AnimatedBox
          variants={RADIO_INPUT_ANIMATION_VARIANTS}
          initial="default"
          animate="enter"
          mt="s">
          {renderChildren}
        </AnimatedBox>
      )}
    </label>
  );
}

export default RadioItem;
