import { TI18nId } from 'types/common';

enum VatRate {
  ZERO = '0',
  TEN = '10',
  TWENTY = '20',
}

const MAP: Record<VatRate, { titleId: TI18nId }> = {
  [VatRate.ZERO]: {
    titleId: 'label.zeroVat',
  },
  [VatRate.TEN]: {
    titleId: 'label.reducedVat',
  },
  [VatRate.TWENTY]: {
    titleId: 'label.standardVat',
  },
};

export function getTitleIdByVatRate(vatRate: VatRate) {
  return MAP[vatRate].titleId;
}

export default VatRate;
