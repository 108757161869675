import { FC } from 'react';

import { CalendarDateIcon, CalendarWeekIcon, CalendarCheck01Icon } from 'components/v2/Icons';

import { TIconProps } from 'components/v2/Icons/Icon.types';
import { TI18nId } from 'types/common';

enum CardSpendingLimitType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  SINGLE_TRANSACTION = 'SINGLE_TRANSACTION',
}

const MAP: Record<
  CardSpendingLimitType,
  {
    labelId: TI18nId;
    icon: FC<TIconProps>;
    requestKey: string;
    resetOnLabelId?: TI18nId;
  }
> = {
  [CardSpendingLimitType.DAILY]: {
    labelId: 'label.daily',
    icon: CalendarDateIcon,
    requestKey: 'daily',
    resetOnLabelId: 'label.resetOn.daily',
  },
  [CardSpendingLimitType.WEEKLY]: {
    labelId: 'label.weekly',
    icon: CalendarWeekIcon,
    requestKey: 'weekly',
    resetOnLabelId: 'label.resetOn.weekly',
  },
  [CardSpendingLimitType.MONTHLY]: {
    labelId: 'label.monthly',
    icon: CalendarCheck01Icon,
    requestKey: 'monthly',
    resetOnLabelId: 'label.resetOn.monthly',
  },
  [CardSpendingLimitType.SINGLE_TRANSACTION]: {
    labelId: 'label.singleTransactionLimit',
    icon: CalendarDateIcon,
    requestKey: 'singleTransaction',
  },
};

export function getLabelIdByCardSpendingLimitType(type: CardSpendingLimitType): TI18nId {
  return MAP[type]?.labelId;
}

export function getIconByCardSpendingLimitType(type: CardSpendingLimitType): FC<TIconProps> {
  return MAP[type].icon;
}

export function getRequestKeyByCardSpendingLimitType(type: CardSpendingLimitType) {
  return MAP[type].requestKey;
}

export function getResetOnLabelIdByCardSpendingLimitType(type: CardSpendingLimitType) {
  return MAP[type].resetOnLabelId ?? 'placeholder.empty';
}

export default CardSpendingLimitType;
