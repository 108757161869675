import React from 'react';

import ListItem from 'components/core/ListItem';
import Modal from 'components/core/Modal';
import Card from 'components/core/Card';

import Currency, {
  getCurrencyByCurrencyCode,
  getIconByCurrency,
  getNameByCurrency,
  getSymbolByCurrency,
} from 'enums/Currency';
import { TI18nId } from 'types/common';

import { TInvoiceItemCurrencySelectModalProps } from './InvoiceItemCurrencySelectModal.types';

function InvoiceItemCurrencySelectModal({
  modalRef,
  onSelect,
}: TInvoiceItemCurrencySelectModalProps) {
  const EurIcon = getIconByCurrency(Currency.EUR);
  const GbpIcon = getIconByCurrency(Currency.GBP);

  function handleNavigate(currency: Currency) {
    modalRef.current?.show(false);
    onSelect(getCurrencyByCurrencyCode(currency));
  }

  return (
    <Modal ref={modalRef} header={{ id: 'modals.invoiceSettingsCurrencySelect.title' }} secondary>
      <Card>
        <ListItem
          titleId="label.sepaZone"
          description={
            `${getSymbolByCurrency(Currency.EUR)} - ${getNameByCurrency(Currency.EUR)}` as TI18nId
          }
          leftIcon={<EurIcon />}
          onClick={() => handleNavigate(Currency.EUR)}
        />
        <ListItem
          titleId="label.unitedKingdom"
          description={
            `${getSymbolByCurrency(Currency.GBP)} - ${getNameByCurrency(Currency.GBP)}` as TI18nId
          }
          leftIcon={<GbpIcon />}
          onClick={() => handleNavigate(Currency.GBP)}
        />
      </Card>
    </Modal>
  );
}

export default InvoiceItemCurrencySelectModal;
