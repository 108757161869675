import React, { forwardRef, Ref } from 'react';
import { NumberFormatPropsBase } from 'react-number-format';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';

import { InputContainer, StyledAmountInput } from './AmountInputBase.styles';
import { TAmountInputBaseProps } from './AmountInputBase.types';

function AmountInputBase(
  {
    id,
    currency,
    renderCurrency = (currency) => currency.symbol,
    hasError = false,
    onChange,
    onValueChange,
    value,
    rightContent,
    amountBottomContent,
    height,
    onFocus,
    autoFocus,
    maxLength = 12,
    isAllowed,
  }: TAmountInputBaseProps,
  ref: Ref<NumberFormatPropsBase<number>>
) {
  return (
    <InputContainer htmlFor={id} hasError={hasError} height={height}>
      <Box display="flex" flexDirection="row" alignItems="center" width="100%">
        <Box flex={1} mr="s">
          <Box display="flex" flexDirection="row" alignItems="center">
            {currency && (
              <Text as="span" variant="titleSection">
                {renderCurrency(currency)}
              </Text>
            )}
            <StyledAmountInput
              id={id}
              getInputRef={ref}
              type="text"
              inputMode="decimal"
              thousandsGroupStyle="thousand"
              allowNegative={false}
              value={value}
              placeholder="0"
              decimalScale={2}
              onChange={onChange}
              maxLength={maxLength}
              onValueChange={onValueChange}
              thousandSeparator
              fixedDecimalScale
              onFocus={onFocus}
              autoFocus={autoFocus}
              isAllowed={isAllowed}
            />
          </Box>
          {amountBottomContent?.()}
        </Box>
        {rightContent?.()}
      </Box>
    </InputContainer>
  );
}

export default forwardRef(AmountInputBase);
