import React, { FocusEvent, forwardRef, Ref, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TextInputLabel from 'components/v2/core/TextInputLabel';
import Box from 'components/core/Box';

import spacing from 'theme/spacing';

import { TNumberInputProps, TNumberInputType } from './NumberInput.types';
import {
  BaseInput,
  InputContainer,
  NumberInputLabelContainer,
  StyledCurrencySymbol,
} from './NumberInput.styles';
import { ICON_SIZE, NUMBER_INPUT_PADDING_VERTICAL_SPACING } from './NumberInput.constants';
import { getLabelColor, getInputColor } from './NumberInput.helpers';

function NumberInput<T extends TNumberInputType>(
  {
    id,
    labelId,
    placeholderId,
    hasError = false,
    editable = true,
    autoFocus = false,
    onFocus,
    onBlur,
    value,
    className,
    height,
    rightIcon: RightIcon,
    style,
    onChange,
    onValueChange,
    currency,
    ...rest
  }: TNumberInputProps<T>,
  ref: Ref<TNumberInputType>
) {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState<boolean>(autoFocus);
  const isPositionTop = !!value || isFocused;

  function handleFocus(event: FocusEvent<T>) {
    onFocus?.(event);
    setIsFocused(true);
  }

  function handleBlur(event: FocusEvent<T>) {
    onBlur?.(event);
    setIsFocused(false);
  }

  function renderRightIcon() {
    if (!RightIcon) {
      return <></>;
    }

    return (
      <Box
        position="absolute"
        right={spacing.m}
        top={NUMBER_INPUT_PADDING_VERTICAL_SPACING}
        paddingY="s"
        pointerEvents="none">
        <RightIcon width={ICON_SIZE} height={ICON_SIZE} iconColor={getInputColor({ editable })} />
      </Box>
    );
  }

  const isPlaceholderVisible = !value && isFocused;

  return (
    <InputContainer style={style} hasError={hasError} editable={editable} height={64}>
      <NumberInputLabelContainer>
        <TextInputLabel
          htmlFor={id}
          textId={labelId}
          textColor={getLabelColor({ isFocused, hasError, editable })}
          isPositionTop={isPositionTop}
        />
      </NumberInputLabelContainer>
      <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
        {currency && isPositionTop && (
          <StyledCurrencySymbol variant="textBodySubBold">{currency.symbol}</StyledCurrencySymbol>
        )}
        <BaseInput
          id={id}
          getInputRef={ref}
          type="text"
          inputMode="decimal"
          $isPlaceholderVisible={isPlaceholderVisible}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholderId ? t(placeholderId) : ''}
          value={value}
          $editable={editable}
          $hasCurrency={!!currency}
          disabled={!editable}
          $hasError={hasError}
          autoFocus={autoFocus}
          className={className}
          height={height}
          decimalScale={0}
          maxLength={12}
          thousandSeparator={false}
          allowNegative={false}
          onValueChange={onValueChange}
          onChange={onChange}
          {...rest}
        />
        {renderRightIcon()}
      </Box>
    </InputContainer>
  );
}

export default forwardRef(NumberInput);
