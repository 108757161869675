import { FC } from 'react';

import { BoxIcon, ClockStopwatchIcon, CalendarDateIcon } from 'components/v2/Icons';

import { TI18nId } from 'types/common';
import { TOption } from 'components/v2/core/RadioGroup/RadioGroup.types';
import { TIconProps } from 'components/v2/Icons/Icon.types';

enum InvoiceItemUnitType {
  PER_ITEM = 'PER_ITEM',
  PER_HOUR = 'PER_HOUR',
  PER_DAY = 'PER_DAY',
}

const MAP: Record<
  InvoiceItemUnitType,
  {
    labelId: TI18nId;
    icon: FC<TIconProps>;
  }
> = {
  [InvoiceItemUnitType.PER_ITEM]: {
    labelId: 'label.perItem',
    icon: BoxIcon,
  },
  [InvoiceItemUnitType.PER_HOUR]: {
    labelId: 'label.perHour',
    icon: ClockStopwatchIcon,
  },
  [InvoiceItemUnitType.PER_DAY]: {
    labelId: 'label.perDay',
    icon: CalendarDateIcon,
  },
};

export function getLabelIdByInvoiceItemUnitType(unitType: InvoiceItemUnitType): TI18nId {
  return MAP[unitType].labelId;
}

export function getIconByInvoiceItemUnitType(unitType: InvoiceItemUnitType) {
  return MAP[unitType].icon;
}

export function getUnitTypeRadioGroup(): TOption<InvoiceItemUnitType>[] {
  return [
    {
      label: getLabelIdByInvoiceItemUnitType(InvoiceItemUnitType.PER_ITEM),
      value: InvoiceItemUnitType.PER_ITEM,
    },
    {
      label: getLabelIdByInvoiceItemUnitType(InvoiceItemUnitType.PER_HOUR),
      value: InvoiceItemUnitType.PER_HOUR,
    },
    {
      label: getLabelIdByInvoiceItemUnitType(InvoiceItemUnitType.PER_DAY),
      value: InvoiceItemUnitType.PER_DAY,
    },
  ];
}

export default InvoiceItemUnitType;
