import styled, { keyframes } from 'styled-components';

import Box from 'components/core/Box/Box';
import { getColor } from 'helpers/theme';

import { getIndicatorColor } from './Radio.helpers';

const radioBackgroundAnimation = keyframes`
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
`;

const radioIndicatorAnimation = keyframes`
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
`;

export const RadioBackground = styled(Box)`
  animation: ${radioBackgroundAnimation} 300ms ease-in forwards;
  transition: all 300ms ease-in;
`;

export const RadioIndicator = styled(Box)`
  animation: ${radioIndicatorAnimation} 300ms ease-in forwards;
  transition: all 300ms ease-in;
  animation-delay: 3000ms;
`;

export const RadioContainer = styled(Box)<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover:checked .radio-indicator {
    background-color: ${getColor(getIndicatorColor({ hovered: true, checked: true }))};
  }

  &:hover .radio-indicator {
    background-color: ${getColor(getIndicatorColor({ hovered: true, checked: false }))};
  }
`;
