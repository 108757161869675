import styled from 'styled-components';
import spacing from 'theme/spacing';

const ActionButtonContainer = styled.button`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${spacing.m};
  cursor: pointer;
`;

export const CardSpendLimitButton = styled.button`
  display: contents;
`;

export default ActionButtonContainer;
