import React from 'react';

import Shimmer from 'components/core/Shimmer';

import { RADIO_SIZE, RADIO_BORDER_RADIUS } from '../Radio.constants';

function RadioShimmer() {
  return (
    <Shimmer
      shape="circle"
      width={RADIO_SIZE}
      height={RADIO_SIZE}
      borderRadius={RADIO_BORDER_RADIUS}
    />
  );
}

export default RadioShimmer;
