import styled, { css } from 'styled-components';
import NumberFormat from 'react-number-format';

import spacing from 'theme/spacing';
import borderRadius from 'theme/borderRadius';
import { FONT_WEIGHT, FONT_SIZE, FONT_FAMILY } from 'theme/v2/fonts';
import { getColor } from 'helpers/theme';
import {
  getBackgroundColor,
  getBorderColor,
} from 'components/v2/core/TextInputBase/TextInputBase.helpers';

export const InputContainer = styled.label<{ hasError: boolean; height: number }>`
  position: relative;
  width: 100%;
  height: ${({ height }) => height}px;
  padding: ${spacing.s} ${spacing.m};
  border-radius: ${borderRadius.s};
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${getColor('transparent')};
  transition: border-color 0.2s linear;
  overflow: hidden;
  background-color: ${({ hasError }) =>
    getColor(
      getBackgroundColor({
        hasError,
        editable: true,
        isFocused: false,
      })
    )};
  border-color: ${({ hasError }) =>
    getColor(getBorderColor({ hasError, editable: true, isFocused: false }))};

  &:hover {
    transition: background-color 0.2s linear;

    ${({ hasError }) =>
      !hasError &&
      css`
        background-color: ${getColor('backgroundSecondaryHover')};
      `}
  }

  &:focus-within {
    background-color: ${({ hasError }) =>
      getColor(getBackgroundColor({ hasError, editable: true, isFocused: true }))};
    border-color: ${({ hasError }) =>
      getColor(getBorderColor({ hasError, editable: true, isFocused: true }))};
  }
`;

export const StyledAmountInput = styled(NumberFormat)`
  border: none;
  outline: none;
  caret-color: ${getColor('textActive')};
  font-size: ${FONT_SIZE.titleSection.fontSize};
  font-weight: ${FONT_WEIGHT.bold};
  font-family: ${FONT_FAMILY};
  height: 2rem;
  width: 100%;
  flex: 1;

  ::placeholder {
    color: ${getColor('textPassive')};
  }
`;
