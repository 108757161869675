import React from 'react';

import Box from 'components/core/Box';
import Pressable from 'components/v2/core/Pressable';
import Text from 'components/v2/core/Text';
import ActivityIndicator from 'components/v2/core/ActivityIndicator';

import { TIconButtonProps } from './IconButton.types';
import { getIconButtonSize, getIconButtonStyle } from './IconButton.helpers';

function IconButton({
  textId,
  variant = 'primary',
  size = 'medium',
  loading = false,
  icon: Icon,
  disabled,
  ...rest
}: TIconButtonProps) {
  return (
    <Pressable disabled={disabled || loading} {...rest}>
      {({ pressed }) => {
        const iconButtonStyle = getIconButtonStyle({ variant, pressed, disabled, loading });
        const iconButtonSize = getIconButtonSize({ size });
        return (
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              alignSelf="center"
              width={iconButtonSize.iconBoxSize}
              height={iconButtonSize.iconBoxSize}
              borderRadius={iconButtonSize.borderRadius}
              borderColor={iconButtonStyle.border}
              backgroundColor={iconButtonStyle.background}>
              {loading ? (
                <Box
                  alignItems="center"
                  justifyContent="center"
                  width={iconButtonSize.iconSize}
                  height={iconButtonSize.iconSize}>
                  <ActivityIndicator color={iconButtonStyle.text} />
                </Box>
              ) : (
                <Icon
                  width={iconButtonSize.iconSize}
                  height={iconButtonSize.iconSize}
                  iconColor={iconButtonStyle.text}
                />
              )}
            </Box>
            {textId && (
              <Text
                textId={textId}
                variant="textBodySubBold"
                color={iconButtonStyle.text}
                textAlign="center"
                mt="s"
              />
            )}
          </Box>
        );
      }}
    </Pressable>
  );
}

export default IconButton;
