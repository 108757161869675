import React from 'react';
import { Controller, UseControllerReturn } from 'react-hook-form';
import { getOr } from 'lodash/fp';

import Box from 'components/core/Box';
import NumberInput from 'components/v2/core/NumberInput';
import FormErrorLabel from 'components/v2/core/FormErrorLabel';

import { TI18nId } from 'types/common';

import { TFieldTextInputProps } from './FieldNumberInput.types';

function FieldNumberInput({
  name,
  labelId,
  placeholderId,
  control,
  errors,
  defaultValue,
  marginBottom = 'm',
  ...rest
}: TFieldTextInputProps) {
  const errorMessageId = getOr(undefined, name, errors)?.message;

  function handleRender({ field }: UseControllerReturn) {
    const { ref, onChange, onBlur, value } = field;
    return (
      <NumberInput
        ref={ref}
        labelId={labelId}
        placeholderId={placeholderId}
        onBlur={onBlur}
        hasError={!!errorMessageId}
        onChange={onChange}
        value={value}
        {...rest}
      />
    );
  }

  return (
    <Box marginBottom={marginBottom}>
      <Controller name={name} control={control} defaultValue={defaultValue} render={handleRender} />
      {errorMessageId && <FormErrorLabel textId={errorMessageId as TI18nId} />}
    </Box>
  );
}

export default FieldNumberInput;
