import React from 'react';

import Card from 'components/core/Card';
import ListItem from 'components/core/ListItem';
import { ListItemShimmer } from 'components/v2/core/ListItem';
import { Speedometer01Icon } from 'components/v2/Icons';
import ProgressBar from 'components/ProgressBar';
import Text from 'components/core/Text';
import Box from 'components/core/Box';

import { formatAmount } from 'helpers/formatAmount';
import dateHelpers from 'helpers/date';
import { useMemberContext } from 'context/MemberProvider';
import MemberType from 'enums/MemberType';

import { TCardSpendLimitSectionProps } from '../CardDetail.types';
import { CardSpendLimitButton } from '../CardDetail.styles';

function CardSpendLimitSection({
  spendingLimits,
  loading,
  onNavigateToCardSpendLimit,
}: TCardSpendLimitSectionProps) {
  const { memberState } = useMemberContext();

  if (loading) {
    return (
      <Card mb="m">
        <ListItemShimmer hasRightIcon />
      </Card>
    );
  }

  if (!spendingLimits?.periodic && !spendingLimits?.singleTransaction) {
    if (memberState?.memberType === MemberType.DIRECTOR) {
      return (
        <Card mb="m">
          <ListItem
            titleId="label.spendingLimits"
            description={{
              id: 'label.setLimits',
              color: 'textPrimary',
            }}
            rightIcon={<Speedometer01Icon iconColor="textSecondary" />}
            onClick={onNavigateToCardSpendLimit}
          />
        </Card>
      );
    }
    return <></>;
  }

  return (
    <CardSpendLimitButton type="button" onClick={onNavigateToCardSpendLimit}>
      <Card mb="m">
        <ListItem
          titleId="label.spendingLimits"
          {...(spendingLimits?.periodic && {
            description: {
              id: 'label.resetsOn.withDate',
              values: {
                date: dateHelpers.format({
                  date: spendingLimits.periodic.resetAt,
                  formatType: 'dd MMMM, HH:mm',
                }),
              },
            },
          })}
          rightIcon={<Speedometer01Icon iconColor="textActive" />}
          disabledHover
        />
        <Box display="grid" gap="m" p="s">
          {spendingLimits?.periodic && (
            <Box px="s" display="grid" gap="m">
              <ProgressBar
                color="textPrimary"
                percentage={
                  (spendingLimits.periodic.usedAmount / spendingLimits.periodic.amount) * 100
                }
              />
              <Text variant="largeTextBold">
                <Text as="span" variant="largeTextBold">
                  {formatAmount({
                    amount: spendingLimits.periodic.usedAmount,
                    currency: spendingLimits.periodic.currency,
                  })}
                </Text>
                <Text as="span" variant="largeTextBold" color="textSecondary">{` /${formatAmount({
                  amount: spendingLimits.periodic.amount,
                  currency: spendingLimits.periodic.currency,
                })}`}</Text>
              </Text>
            </Box>
          )}
          {spendingLimits?.singleTransaction && (
            <Box display="flex" gap="xs" px="s" mb="s" flexDirection="row" alignItems="center">
              <Box flex={1}>
                <Text
                  id="label.singleTransactionLimit"
                  variant="mediumTextRegular"
                  color="textSecondary"
                />
              </Box>
              <Text variant="mediumTextBold">
                {formatAmount({
                  amount: spendingLimits.singleTransaction.amount,
                  currency: spendingLimits.singleTransaction.currency,
                })}
              </Text>
            </Box>
          )}
        </Box>
      </Card>
    </CardSpendLimitButton>
  );
}

export default CardSpendLimitSection;
