import React, { useImperativeHandle, useState } from 'react';

import Modal, { useModal } from 'components/core/Modal';
import Card from 'components/core/Card';
import RadioGroup from 'components/v2/core/RadioGroup';
import Box from 'components/core/Box';
import Button from 'components/v2/core/Button';

import CardSpendingLimitType, { getIconByCardSpendingLimitType } from 'enums/CardSpendingLimitType';

import { TCardSpendingLimitTypeSelectModalProps } from './CardSpendingLimitTypeSelectModal.types';
import { CARD_SPENDING_LIMIT_TYPE_OPTIONS } from './CardSpendingLimitTypeSelectModal.constants';

function CardSpendingLimitTypeSelectModal({
  modalRef,
  onSelectCardSpendingLimitType,
}: TCardSpendingLimitTypeSelectModalProps) {
  const ref = useModal();
  const [selectedCardSpendingLimitType, setSelectedCardSpendingLimitType] =
    useState<CardSpendingLimitType>();

  useImperativeHandle(
    modalRef,
    () => ({
      show: (state) => {
        setSelectedCardSpendingLimitType(state?.cardSpendingLimitType);
        ref.current?.show(true);
      },
    }),
    []
  );

  function handleConfirm() {
    if (selectedCardSpendingLimitType) {
      onSelectCardSpendingLimitType(selectedCardSpendingLimitType);
      ref.current?.show(false);
    }
  }

  return (
    <Modal ref={ref} header={{ id: 'label.frequency' }} secondary>
      <Card p="s">
        <RadioGroup<CardSpendingLimitType>
          id="frequency"
          options={CARD_SPENDING_LIMIT_TYPE_OPTIONS}
          value={selectedCardSpendingLimitType}
          onChange={(option) => setSelectedCardSpendingLimitType(option.value)}
          renderOptionItem={(option) => ({
            id: option.value,
            icon: getIconByCardSpendingLimitType(option.value),
          })}
        />
      </Card>
      <Box display="flex" flexDirection="column" gap="m" mt="m">
        <Button
          labelId="button.cancel"
          variant="secondary"
          onClick={() => ref.current?.show(false)}
        />
        <Button labelId="button.select" onClick={handleConfirm} />
      </Box>
    </Modal>
  );
}

export default CardSpendingLimitTypeSelectModal;
