import React, { useMemo, useState } from 'react';

import Modal from 'components/core/Modal';
import Card from 'components/core/Card';
import Box from 'components/core/Box';
import RadioGroup from 'components/v2/core/RadioGroup';
import Button from 'components/v2/core/Button';

import InvoiceItemUnitType, {
  getIconByInvoiceItemUnitType,
  getUnitTypeRadioGroup,
} from 'enums/InvoiceItemUnitType';

import { TInvoiceSellItemUnitTypeSelectModalProps } from './InvoiceSellItemUnitTypeSelectModal.types';

function InvoiceSellItemUnitTypeSelectModal({
  modalRef,
  unitType,
  onSelectUnitType,
}: TInvoiceSellItemUnitTypeSelectModalProps) {
  const [selectedUnitType, setSelectUnitType] = useState<InvoiceItemUnitType>(unitType);
  const options = useMemo(() => getUnitTypeRadioGroup(), []);

  function handleConfirm() {
    onSelectUnitType(selectedUnitType);
    modalRef.current?.show(false);
  }

  return (
    <Modal ref={modalRef} header={{ id: 'label.itemUnit' }}>
      <Card p="s" mb="m">
        <RadioGroup<InvoiceItemUnitType>
          id="unitType"
          options={options}
          value={selectedUnitType}
          onChange={(option) => setSelectUnitType(option.value)}
          renderOptionItem={(option) => ({
            id: option.value,
            icon: getIconByInvoiceItemUnitType(option.value),
          })}
        />
      </Card>
      <Box display="flex" flexDirection="column" gap="m">
        <Button
          labelId="button.cancel"
          variant="secondary"
          onClick={() => modalRef.current?.show(false)}
        />
        <Button labelId="button.save" onClick={handleConfirm} />
      </Box>
    </Modal>
  );
}

export default InvoiceSellItemUnitTypeSelectModal;
