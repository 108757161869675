import React from 'react';

import Text from 'components/v2/core/Text';

import NavigationHeaderBaseButton from './views/NavigationHeaderBaseButton';
import { TNavigationHeaderCloseButtonProps } from './Navigation.types';

function NavigationHeaderRemoveButton({ onPress }: TNavigationHeaderCloseButtonProps) {
  return (
    <NavigationHeaderBaseButton onPress={onPress}>
      <Text textId="button.remove" variant="textBodySmall" color="statusErrorPrimary" />
    </NavigationHeaderBaseButton>
  );
}

export default NavigationHeaderRemoveButton;
