import React from 'react';

import { FieldTextInput, FieldTextAreaInput } from 'components/v2/Fields';
import PageHeader from 'components/v2/PageHeader';
import Button from 'components/v2/core/Button';
import Box from 'components/core/Box';
import { EyeIcon } from 'components/v2/Icons';

import { BaseForm, useForm } from 'components/v2/Forms';

import {
  TInvoiceEmailMessageProps,
  TInvoiceEmailMessageFormData,
} from './InvoiceEmailMessageForm.types';
import {
  FORM_VALIDATION_SCHEMA,
  MAIL_BODY_MAX_LENGTH,
  TEXT_AREA_HEIGHT,
  TITLE_MAX_LENGTH,
} from './InvoiceEmailMessageForm.constants';

function InvoiceEmailMessageForm({
  titleId,
  descriptionId,
  renderInvoicePdfPreviewComponent,
  onSubmit,
  loading,
  defaultValues,
  submitButtonLabelId = 'button.save',
  onPreviewEmail,
  onPreviewPdf,
}: TInvoiceEmailMessageProps) {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<TInvoiceEmailMessageFormData>({
    schema: FORM_VALIDATION_SCHEMA,
    defaultValues,
  });

  function handlePreviewEmail() {
    const values = getValues();
    onPreviewEmail(values);
  }

  function handlePreviewPdf() {
    const values = getValues();
    onPreviewPdf?.(values);
  }

  return (
    <BaseForm
      onSubmit={handleSubmit(onSubmit)}
      submitButtonLabelId={submitButtonLabelId}
      submitButtonProps={{ loading }}>
      <PageHeader titleId={titleId} descriptionId={descriptionId} />
      <Box mb="m">
        <Button
          labelId="button.preview"
          variant="ghost"
          leftIcon={EyeIcon}
          block={false}
          onClick={handlePreviewEmail}
          disabled={!isValid}
        />
      </Box>
      {renderInvoicePdfPreviewComponent?.({
        onPress: handlePreviewPdf,
      })}
      <FieldTextInput
        name="shareInvoiceEmailTitle"
        labelId="label.title"
        placeholderId="placeholder.empty"
        control={control}
        errors={errors}
        maxLength={TITLE_MAX_LENGTH}
      />
      <FieldTextAreaInput
        name="shareInvoiceEmailBody"
        labelId="label.mailBody"
        placeholderId="placeholder.empty"
        control={control}
        errors={errors}
        maxLength={MAIL_BODY_MAX_LENGTH}
        inputHeight={TEXT_AREA_HEIGHT}
      />
    </BaseForm>
  );
}

export default InvoiceEmailMessageForm;
