import React, { Key, useState } from 'react';
import { motion } from 'framer-motion';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';

import { TSegmentedControlProps } from './SegmentedControl.types';
import StyledPressable from './SegmentedControl.styles';
import { SEGMENTED_CONTROL_ANIMATION_CONFIG } from './SegmentedControl.constants';

const AnimatedBox = motion(Box);

function SegmentedControl<T>({ options, defaultValue, onChange }: TSegmentedControlProps<T>) {
  const [selectedOptionValue, setSelectedOptionValue] = useState<T>(defaultValue);

  const selectedIndex = options.findIndex(({ value }) => value === selectedOptionValue);
  const indicatorWidth = 100 / options.length;
  const indicatorStep = 100 * selectedIndex;

  function handleOptionOnPress(value: T) {
    if (selectedOptionValue !== value) {
      setSelectedOptionValue(value);
      onChange?.(value);
    }
  }

  return (
    <Box
      position="relative"
      display="flex"
      width="100%"
      borderWidth={2}
      borderRadius={16}
      borderStyle="solid"
      borderColor="backgroundSecondary"
      backgroundColor="backgroundSecondary"
      height={40}>
      <AnimatedBox
        display="flex"
        backgroundColor="backgroundTertiary"
        width={`${indicatorWidth}%`}
        animate={{ translateX: `${indicatorStep}%` }}
        transition={SEGMENTED_CONTROL_ANIMATION_CONFIG}
        position="absolute"
        height={36}
        borderRadius={14}
      />
      {options.map(({ labelId, value }) => {
        const isSelected = selectedOptionValue === value;
        return (
          <StyledPressable key={value as Key} onClick={() => handleOptionOnPress(value)}>
            <Text
              textId={labelId}
              variant={isSelected ? 'textBodyBold' : 'textBody'}
              color={isSelected ? 'textPrimary' : 'textSecondary'}
              numberOfLines={1}
            />
          </StyledPressable>
        );
      })}
    </Box>
  );
}

export default SegmentedControl;
